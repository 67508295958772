import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    
    content:{
        marginTop: 100,
        marginBottom: 20,
        padding: 0,
        [theme.breakpoints.down('md')]:{
            marginTop: 20,
        },
    },

    Title:{
        color: Colors.red,
        fontSize: 50,
        fontFamily: 'Phosphate'
    },

    subTitle:{
        fontSize: 30,
        fontFamily: 'Move',
        [theme.breakpoints.only('xs')]:{
            fontSize: 20,
        },
    },
}))

const Touch = ({ title, subTitle, }) => {
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={ classes.content }>
            <Grid container>
            {/* Empty Space  */}
            <Grid item lg={1} xs={1}></Grid>

            {/* Texts */}
            <Grid item lg={11} xs={11} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={ classes.Title } style={{ margin:0 }}> { title } </span>
                <span className={ classes.subTitle } style={{ margin:0 }}> { subTitle } </span>
            </Grid>
        </Grid>

        </Container>
    );
}
export default Touch;