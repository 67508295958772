import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "../redux";
import { categorySelected } from "../redux/actions/Cart";

let url = "https://us-central1-dulces-micho.cloudfunctions.net/api/";
let introWeb = "introWeb";
let categories = "categoryWeb";
let products = "productsWeb";
let contact = "contact-email";
let validateDiscount = "validate-discount";
let recipes = "recipes-web";
let sales = "sales-web";

const emailformat = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/;
const phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

// Endpoint Content
export const getDataContent = async () => {
  let ruta = url + introWeb;
  const method = {
    method: "GET",
  };

  let data = [];
  await fetch(ruta, method)
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      data.push(res);
    });

  return data[0];
};

// Endopoint Categories
export const getDataCategories = async () => {
  let ruta = url + categories;
  const method = {
    method: "GET",
  };

  let data = [];
  await fetch(ruta, method)
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      data.push(res);
    });

  if (data.length !== 0) {
    const name = data[0][0].Name;
    store.dispatch(categorySelected(name));
  }

  return data[0];
};

// Endpoint Products
export const getDataProducts = async () => {
  let ruta = url + products;
  const method = {
    method: "GET",
  };

  let data = [];
  await fetch(ruta, method)
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      data.push(res);
    });
  return data[0];
};

// Endpoint Recipes
export const getRecipes = async () => {
  let ruta = url + recipes;
  const method = {
    method: "GET",
  };

  let data = [];
  await fetch(ruta, method)
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      data.push(res);
    });

  return data[0];
};

// Format Money
export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (error) {
    console.log(error);
  }
};

// Post Contact Data
export const postContact = async (name, phone, email, message) => {
  let ruta = url + contact;
  let obj = {};

  obj.Name = name;
  obj.Email = email;
  obj.Phone = phone;
  obj.Message = message;

  const method = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(ruta, method).then((response) => {
    if (response.status === 200) {
      toast.success(" Datos enviados con exito ");
      console.log(response.status);
    } else {
      toast.warn("Ocurrio un Error");
      console.log(response.status);
    }
  });
};

// Validate Contact Data
export const contactValidations = (name, phone, email, message) => {
  let flag = false;

  // Validation Name
  if (name.length <= 3 || name.length === 0) {
    toast.warn("No nos has dado a conocer tu nombre");
    return false;
  }

  // Validation Phone
  if (!phone.match(phoneformat)) {
    toast.warn('El campo "Teléfono" es requerido ');
    return false;
  }

  // Validation Email
  if (!email.match(emailformat)) {
    toast.warn('El campo "Correo" es requerido ');
    return false;
  }

  // Validation Message
  if (message.length === 0) {
    toast.warn('El campo "Mensaje" es requerido');
    return false;
  }

  flag = true;

  return flag;
};

// Validate Costumer Info
export const infoContactValidations = (name, address, state, colony, phone, municipality, postalCode, email) => {
  let flag = false;

  // Validation Name
  if (name.length <= 3) {
    toast.warn("No nos has dado da conocer tu nombre");
    return false;
  }

  // Validation Adress
  if (address.length <= 3) {
    toast.warn('El campo "Calle y Número" es requerido');
    return false;
  }

  // Validation State
  if (state.length === 0) {
    toast.warn('El campo "Estado" es requerido');
    return false;
  }

  // Validation Colony
  if (colony.length <= 3) {
    toast.warn('El campo "Colonia" es requerido');
    return false;
  }

  // Validation Phone
  if (!phone.match(phoneformat)) {
    toast.warn('El campo "Teléfono" es requerido');
    return false;
  }

  // Validation Municipality
  if (municipality.length <= 3) {
    toast.warn('El campo "Municipio" es requerido');
    return false;
  }

  // Validation Postal Code
  if (postalCode <= 5) {
    toast.warn('El campo "Código Postal" es requerido ');
    return false;
  }
  // Validation Email
  if (!email.match(emailformat)) {
    toast.warn('El campo "Correo" es requerido ');
    return false;
  }

  flag = true;

  return flag;
};

// Send and recive Code Discount details
export const postCodeDiscount = async (codeDiscount) => {
  let ruta = url + validateDiscount;
  let obj = {};
  obj.Code = codeDiscount;

  const method = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = [];
  await fetch(ruta, method)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log(response.status);
      }
    })

    .then((res) => {
      data.push(res);
    });

  return data[0];
};

export const postSale = async (products, discountCode, discountType, discount, subTotal, shipment, contact) => {
  let ruta = url + sales;
  let obj = {};

  obj.Products = products;
  obj.DiscountCode = discountCode;
  obj.Type = discountType;
  obj.Discount = discount;
  obj.Total = subTotal;
  obj.Shipping = shipment;
  obj.Contact = contact;

  // console.log('Venta: ', obj)

  const method = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(ruta, method).then((response) => {
    if (response.status === 200) {
      console.log(response.status);
    } else {
      console.log(response.status);
    }
  });
};

export const deleteAgentStep1 = async (email) => {
  let flag = false;

  const method = {
    method: "DELETE",
  };

  const auxUrl = url + "deleteAgentsStep1/" + email.toLowerCase().trim();

  await fetch(auxUrl, method).then((ref) => {
    if (ref.ok) {
      flag = true;
    }
  });

  return flag;
};

export const deleteAgentsStep2 = async (id) => {
  let flag = false;

  const method = {
    method: "DELETE",
  };

  const auxUrl = url + "deleteAgentsStep2/" + id;

  await fetch(auxUrl, method).then((ref) => {
    if (ref.ok) {
      flag = true;
    }
  });

  return flag;
};
