import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content:{
        marginTop: 20,
        marginBottom: 20,
        padding: 0,
    },

    background: {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: 450,
        [theme.breakpoints.down('md')]:{
            backgroundSize:'cover',
            backgroundPosition: 'center',
            height: 525,
        }
    },

    overContainer:{
        // background: 'pink',
        display:'flex', 
        alignItems:'center', 
        height:'100%',
        width: '100%',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center',
        }
    },
    
    over:{
        height:'70%',
        [theme.breakpoints.down('md')]:{
            height:'20%',
        }

    }

}))

const BannerB = ({ background, over }) => {
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={ classes.content }>
            <div className={ classes.background } style={{ backgroundImage: `url(${ background })` }}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item lg={1} />

                    <Grid item lg={11} className={ classes.overContainer }>
                        <img className={ classes.over } src={over} alt='over'></img>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default BannerB;