import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Container, Grid, IconButton, Badge } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import Pattern from '../../../media/images/pattern_a.svg';
import ImageBag from '../../../media/images/ic_bag_big.svg';

import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    content:{
        marginTop: 50,
        marginBottom: 25,
        padding: 0,
        height: 250
    },

    pattern_bg:{
        width: '100%',
        height: '100%',
        backgroundSize: '75px 75px',
        backgroundRepeat: 'repeat',
        // backgroundImage: `url(${Pattern})`
    },

    containerTitleMain:{
        height: '100%', 
        width: '100%',
        display: 'flex', 
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center'
        }
    },

    titleMain: {
        fontFamily: 'Gochi', 
        fontSize: 100,
        color: Colors.white,
        [theme.breakpoints.down('md')]:{
            fontSize: 50
        }
    },

    buttonCart: {
        width: 125, 
        height: 125,
        backgroundImage: `url(${ImageBag})`,
        backgroundPosition:'center', 
        backgroundSize: '150px 150px',
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        '&:focus':{
            outline: 'none'
        },
    },

    displayImageMainCart: {
        height: '100%', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent:'flex-end',
        [theme.breakpoints.down('md')]:{
            justifyContent:'center',
        }
    },


    imageMainCart: {
        width: 135,
        [theme.breakpoints.down('md')]:{
            width: 120
        }
    }

}))

const StyledBadge = withStyles(theme => ({
    badge: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        fontSize: 20,
        border: `3px solid ${Colors.white}`,
        color: Colors.white,
        background: Colors.redAdd,
        top: '20%',
        right: 20,
    }, 
}))(Badge)

const Main = ({ openCart, setOpenCart, Products }) => {
    const classes = useStyles();

    const calculatedTotal = () =>{
        let total = 0
        Products.forEach(element => {
            total += element.Quantity
        });
        return total
    }

    return(
        <div>
            <Container maxWidth={false} className={ classes.content }>
                <div className={ classes.pattern_bg } style={{ background: `linear-gradient(180deg, #F6F012, #42B748` }}>
                    <Grid container style={{ height:'100%' }}>
                        <Grid item xl={1} lg={1}/>

                        <Grid item xl={5} lg={5} xs={7} >
                            <div className={ classes.containerTitleMain }>
                                <span className={ classes.titleMain } >Productos</span>
                            </div>
                        </Grid>

                        <Grid item xl={5} lg={5} xs={5}>
                            <div className={ classes.displayImageMainCart }>
                                <IconButton onClick= { e => {setOpenCart(!openCart) }} >
                                    <StyledBadge badgeContent={ calculatedTotal() } color="primary">
                                        <img className={ classes.imageMainCart } src={ ImageBag } alt='Cart'></img>
                                    </StyledBadge>
                                </IconButton>
                            </div>
                        </Grid>

                        <Grid item xl={1} lg={1}/>

                    </Grid>
                </div>
            </Container>
        </div>
    );
}

const mapStateProps = state => ({
    Products:       state.Cart.products,
})

export default connect( mapStateProps,{ } )(Main);