import React from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../values";

const keyMap = "AIzaSyACo88xZU0ZyHipEkrL2Em-TMeB1VMEsPM";

// Marker Map
const Marker = ({ markerIcon, markerClass }) => (
  <div>
    <img className={markerClass} src={markerIcon} alt="Marker" />
  </div>
);

const useStyles = makeStyles((theme) => ({
  marker: {
    width: 50,
    height: "auto",
    transform: "translate(-50%, -100%)",
    background: Colors.white,
    border: `1px solid ${Colors.black}`,
    borderRadius: "45%",
    padding: 5,
  },

  map: {
    width: "100%",
    position: "relative",
    height: 700,
    [theme.breakpoints.down("md")]: {
      height: 500,
    },
  },
}));

const Map = ({ markerIcon }) => {
  const classes = useStyles();
  const MAP_OPTIONS = { scrollwheel: false };
  const defaultProps = {
    center: {
      lat: 20.487134,
      lng: -103.49435,
    },
    zoom: 18,
  };

  return (
    <div className={classes.map}>
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          // bootstrapURLKeys={{ key: keyMap }}
          bootstrapURLKeys={{ libraries: "drawing", key: keyMap }}
          tracksViewChanges={false}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={MAP_OPTIONS}
        >
          <Marker markerClass={classes.marker} markerIcon={markerIcon} lat={20.487134} lng={-103.49435} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
