import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';

const useStyles = makeStyles(theme => ({
    content:{
        background: '#D9F1DA',
        marginTop: 20,
        marginBottom: 20,
        padding: 0
    },
    bannerImg:{
        width:'100%',
        [theme.breakpoints.down('sm')]:{
            width: '100%'
        }
    }
}))

const Banner = ({ imageA, imageB }) => {
    const classes = useStyles()

    return(
        <Container maxWidth={false} className={ classes.content }>
            <Grid container style={{ height:'100%' }}>
                <Grid item xl={6} lg={6} xs={12} >
                    <div style={{ width:'100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        <div >
                            <img className={classes.bannerImg} src={ imageA } alt='Micho Twist 1'></img>
                        </div>
                    </div>
                </Grid>

                <Grid item xl={6} lg={6} xs={12} >
                    <div style={{ width:'100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                        <div >
                            <img className={classes.bannerImg} src={ imageB } alt='Micho Twist 2'></img>
                        </div>
                    </div>
                </Grid> 
            </Grid>
        </Container>
    );
}

export default Banner;