import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import Menu from "./components/global/Menu";
import Home from "./components/home/HomeContainer";
import Company from "./components/company/CompanyContainer";
import Products from "./components/products/ProductsContainer";
import Recipes from "./components/recipes/RecipesContainer";
import Contact from "./components/contact/ContactContainer";
import Footer from "./components/global/Footer";
import Intro from "./components/global/Intro";
import PrivacyPolicies from "./components/global/PrivacyPolicies";
import DeleteAccount from "./components/global/DeleteAccount";
import ScrollToTop from "./components/global/ScrollTop";
import { getDataContent, getDataCategories, getDataProducts, getRecipes } from "./utils/BaseBuilder";

// target comand
// firebase target:apply hosting page micho-page

function App() {
  const [base, setBase] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [state, setState] = useState(false);

  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingRecipes, setLoadingRecipes] = useState(false);

  useEffect(() => {
    if (loadingContent === false) {
      dataContent();
    }
    if (loadingCategories === false) {
      dataCategories();
    }
    if (loadingProducts === false) {
      dataProducts();
    }
    if (loadingRecipes === false) {
      dataRecipes();
    }
    if (loadingContent && loadingCategories && loadingProducts && loadingRecipes) {
      setTimeout(() => {
        setState(true);
      }, 1200);
    }
  });

  const dataContent = async () => {
    setLoadingContent(true);
    const resultado = await getDataContent();
    setBase(resultado);
  };

  const dataCategories = async () => {
    setLoadingCategories(true);
    const resultado = await getDataCategories();
    setCategories(resultado);
  };

  const dataProducts = async () => {
    setLoadingProducts(true);
    const resultado = await getDataProducts();
    // console.log("PRODUCTOS: ", resultado);
    setProducts(resultado);
  };

  const dataRecipes = async () => {
    setLoadingRecipes(true);
    const resultado = await getRecipes();
    setRecipes(resultado);
  };

  return (
    <div>
      <div style={{ display: state === false ? "block" : "none" }}>
        <Intro />
      </div>

      <div style={{ display: state === true ? "block" : "none" }}>
        <Router>
          <ScrollToTop>
            <ToastContainer />
            <Menu baseContent={base} />

            <Route exact path="/">
              <Home baseContent={base} dataRecipes={recipes} />
            </Route>

            <Route exact path="/inicio">
              <Home baseContent={base} dataRecipes={recipes} />
            </Route>

            <Route exact path="/empresa">
              <Company baseContent={base} />
            </Route>

            <Route exact path="/recetas">
              <Recipes baseContent={base} dataRecipes={recipes} />
            </Route>

            <Route exact path="/productos">
              <Products baseContent={base} dataCategories={categories} dataProducts={products} />
            </Route>

            <Route exact path="/contacto">
              <Contact baseContent={base} />
            </Route>

            <Route exact path="/politicas_de_privacidad">
              <PrivacyPolicies />
            </Route>

            <Route exact path="/delete_account/:id?">
              <DeleteAccount />
            </Route>

            <Footer baseContent={base} />
          </ScrollToTop>
        </Router>
      </div>
    </div>
  );
}

export default App;
