import React from 'react';
import OvalB from '../../../media/images/oval_b.svg';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'; 
import { categorySelected } from '../../../redux/actions/Cart';

const useStyles = makeStyles(theme => ({

    content: {
        marginTop: 25,
        marginBottom: 100,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            marginBottom: 50
        }
    },

    itemUnselected:{
        transition: '0.5s',
        width: 115,
        height: 115,
        borderRadius: '50%',
        backgroundPosition: 'center',
        backgroundSize: '115px 115px',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        margin: 15,
        '&:focus':{
            outline: 'none'
        }
        ,'&:hover':{
            transition: '0.2s',
            transform: 'scale(1.2)'
        },
        [theme.breakpoints.down('lg')]:{
            transition: '0.5s',
            width: 100,
            height: 100,
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundSize: '100px 100px',
            backgroundRepeat: 'no-repeat',
            border: 'none',
            cursor: 'pointer',
        }
    },

    itemSelected:{
        width: 150,
        height: 150,
        borderRadius: '50%',
        backgroundPosition: 'center',
        backgroundSize: '150px 150px',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        margin: 10,
        '&:focus':{
            outline: 'none'
        }
    },

    selected: {
        animation: `$rote 750ms infinite`,
        },
        '@keyframes rote': {
            '100%':{
                transform: 'rotate(360deg)'
            }
        }
}))

const Category = ({ icon , name, categorySelected, Category }) => {
    const classes = useStyles();

    return(
        <div className={ classes.content }>
            {
                name === Category?
                <div >
                    <img className={classes.selected} src={OvalB} style={{ position: 'absolute', height: 170, width:170, zIndex:0 }} alt='Oval'></img>
                    <button className={ classes.itemSelected } style={{ backgroundImage: `url(${ icon })`, position: 'relative',zIndex: 1}} onClick={ () => categorySelected(name) }></button>
                </div>
                :
                    <button className={ classes.itemUnselected } style={{ backgroundImage: `url(${ icon })`}} onClick={ () => categorySelected(name) }></button>
            }
        </div>
    );
}

const mapStateProps = (state) => ({
    Category:       state.Cart.category
})

export default connect(mapStateProps, { categorySelected })(Category); 

