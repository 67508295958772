import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../values';
import Pattern from '../../media/images/pattern_a.svg';
import '../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    content:{
        marginTop: 50,
        marginBottom: 25,
        padding: 0,
        height: 250,
        [theme.breakpoints.down('md')]:{
            marginBottom: 20
        }
    },

    pattern_bg:{
        width: '100%',
        height: '100%',
        backgroundSize: '75px 75px',
        backgroundRepeat: 'repeat',
        // backgroundImage: `url(${Pattern})`
    },

    containerTitleMain:{
        height: '100%', 
        width: '100%',
        display: 'flex', 
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center'
        }
    },

    titleMain: {
        fontFamily: 'Gochi', 
        fontSize: 100,
        color: Colors.white,
        [theme.breakpoints.down('md')]:{
            fontSize: 55
        }
    },

    containerImgMain: {
        height: '100%', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center'
        }
    }

}))

const Main = ({ title, icon, bgColor1, bgColor2, widthImg='' }) => {
    const classes = useStyles()
    return(
        <div>
            <Container maxWidth={false} className={ classes.content }>
                <div className={ classes.pattern_bg } style={{ background: `linear-gradient(90deg, ${bgColor1}, ${bgColor2})`}}>
                    <Grid container style={{ height:'100%' }}>
                        <Grid item xl={1} lg={1}/>

                        <Grid item xl={5} lg={5} xs={7} >
                            <div className={ classes.containerTitleMain }>
                                <span className={ classes.titleMain }>{ title }</span>
                            </div>
                        </Grid>

                        <Grid item xl={5} lg={5} xs={5} >
                            <div className={ classes.containerImgMain }>
                                <img style={{ width: widthImg }} src={ icon } alt={ title }></img>
                            </div>
                        </Grid>

                        <Grid item xl={1} lg={1}/>

                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default Main;