import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    
    content:{
        marginTop: 100,
        marginBottom: 100,
        padding: 0,
        [theme.breakpoints.down('md')]:{
            marginTop: 25,
            marginBottom: 25,
        }
    },

    textContainer:{
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center',
            alignItems:'center',
        }
    },

    Title:{
        color: Colors.red,
        fontSize: 50,
        fontFamily: 'Phosphate',
        [theme.breakpoints.down('md')]:{
            fontSize: 27,
        }
    },

    subTitle:{
        fontWeight: 700,
        fontSize: 30,
        fontFamily: 'Move',
        [theme.breakpoints.down('md')]:{
            fontSize: 15
        }
    }
    
}))

const Touch = ({ title, subTitle, }) => {
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={ classes.content }>
            <Grid container>
            {/* Empty Space  */}
            <Grid item lg={1}></Grid>

            {/* Texts */}
            <Grid item lg={11} xs={12}>
                <div className={classes.textContainer}>
                    <span className={ classes.Title } style={{ margin:0 }}> { title } </span>
                    <span className={ classes.subTitle } style={{ margin:0 }}> { subTitle } </span>
                </div>
            </Grid>
        </Grid>

        </Container>
    );
}
export default Touch;