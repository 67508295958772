const initialState = {
    products:       [],
    category:       '',
    sale:           []
}

const Cart = (state = initialState, action) =>{
    if(action.type === "AGREGA"){
        return {
            ...state,
            products:      action.data,
        }
    }

    if(action.type === "LIMPIAR"){
        return {
            ...state,
            products:      [],
        }
    }

    if(action.type === "CAMBIAR"){
        return {
            ...state,
            category:       action.data,
        }
    }

    if(action.type === "ENVIAR"){
        return {
            ...state,
            category:       action.data,
        }
    }

    return state
}
export default Cart