import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css'

const useStyles = makeStyles(theme => ({
    content:{
        marginTop: 50,
        marginBottom: 100,
        padding: 0,
        [theme.breakpoints.down('md')]:{
            marginTop: 25,
            marginBottom: 20
        }
    },

    title:{
        fontSize: 50,
        fontFamily: 'Phosphate',
        color: Colors.red
    },

    subTitle: {
        fontFamily: 'Move',
        fontSize: 30
    },

    text: {
        whiteSpace: 'pre-line',
        fontFamily: 'Book',
        fontSize: 16,
        [theme.breakpoints.down('md')]:{
            textAlign: 'justify'
        }
    },
    
    displayVision:{
        paddingRight: 50,
        [theme.breakpoints.down('md')]:{
            paddingRight: 0,
            marginBottom: 25
        }
    },

    displayMision:{
        paddingLeft: 50,
        [theme.breakpoints.down('md')]:{
            paddingLeft: 0
        }
    }

}))

const MissionVision = ({ titleVision, textVision, titleMission, textMission }) => {
    const classes = useStyles()

    return(
        <div>
            <Container maxWidth={false} className={ classes.content }>
                <Grid container style={{ height: '100%' }}>
                    {/* Empty Space */}
                    <Grid item xl={1} lg={1}/>
                    
                    {/* Vision */}
                    <Grid item xl={5} lg={5} xs={12}>
                        <Grid container>
                            <Grid item xs={1} />
                            
                            <Grid item xl={12} lg={12} xs={10}>
                                <div className={ classes.displayVision }>
                                    <span className={ classes.subTitle }>{ titleVision }</span>
                                    <p className={ classes.text }>{ textVision }</p>
                                </div>
                            </Grid>

                            <Grid item xs={1}/>
                        </Grid>
                    </Grid>

                    {/* Mision */}
                    <Grid item xl={5} lg={5}>
                        <Grid container> 
                            <Grid item xs={1}/>

                            <Grid item xl={12} lg={12} xs={10}>
                                <div className={ classes.displayMision }>
                                    <span className={ classes.subTitle }>{ titleMission }</span>
                                    <p className={ classes.text }>{ textMission }</p>
                                </div>
                            </Grid>

                            <Grid item xs={1}/>
                        </Grid>
                    </Grid>

                    {/* Empty Space */}
                    <Grid item xl={1} lg={1} />
                </Grid>
            </Container>
        </div>
    );
}

export default MissionVision;