import React from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";

import Background_footer from "../../media/images/background_footer.svg";
import LottieFooter from "../../media/animations/lottieFooter.json";

import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../values";
import "../../fonts/font.css";

import packageJson from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 950,
    },
  },
  background: {
    width: "100%",
    height: "100%",
    background: Colors.red,
    position: "relative",

    backgroundImage: `url(${Background_footer})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgFooter: {
    height: 250,
    width: 250,
    [theme.breakpoints.down("sm")]: {
      height: 220,
      width: 220,
    },
  },
  borderLottie: {
    position: "absolute",
    height: 200,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      height: 175,
      width: 175,
    },
  },
  lottie_container: {
    position: "absolute",
    height: 175,
    width: 175,
    background: Colors.white,
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      height: 150,
      width: 150,
    },
  },
  mexicoMap: {
    position: "relative",
    left: 100,
    height: 100,
    width: 100,
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: 80,
      left: 80,
    },
  },
  container2: {
    height: " 100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // paddingTop:100,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      // display:'block'
    },
  },
  container3: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      display: "block",
    },
  },
  containerDesc: {
    marginLeft: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  textDesc: {
    fontFamily: "Book",
    whiteSpace: "pre-line",
    lineHeight: 0.9,
    color: Colors.white,
    marginTop: 30,
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  privacyPolicies: {
    color: "#fff",
    position: "absolute",
    bottom: -20,
    width: 200,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      bottom: -50,
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
    },
  },
}));

const Footer = ({ baseContent }) => {
  const classes = useStyles();
  const history = useHistory();

  const version = packageJson.version;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.background}>
      <Container maxWidth={false} className={classes.content}>
        {/* <div className={ classes.pattern } /> */}
        {baseContent.map((item, index) => (
          <Grid container key={index} style={{ height: "100%", width: "100%" }}>
            {/* Dulces Micho Logo */}
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <div className={classes.container}>
                <img className={classes.imgFooter} src={item.Footer.Logo} alt="Dulces Micho"></img>
              </div>
            </Grid>

            {/* Location and Contact info */}
            <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
              <div className={classes.container2}>
                <div className={classes.container3}>
                  <div>
                    {/* Location info */}
                    <div className={classes.textDesc}>
                      <span style={{ fontWeight: "bold", fontFamily: "Heavy" }}>
                        {item.Contact.Data.Location.Title}
                      </span>
                      <p> {item.Contact.Data.Location.Description} </p>
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    {/* Phone info */}
                    <div className={classes.textDesc} style={{ marginBottom: 50 }}>
                      <span style={{ fontWeight: "bold", fontFamily: "Heavy" }}> {item.Contact.Data.Phone.Title} </span>
                      <p> {item.Contact.Data.Phone.Description} </p>
                    </div>

                    <p onClick={() => history.push("/politicas_de_privacidad")} className={classes.privacyPolicies}>
                      Politicas de privacidad
                    </p>
                  </div>
                  <div>
                    {/* Email info */}
                    <div className={classes.textDesc}>
                      <span style={{ fontWeight: "bold", fontFamily: "Heavy" }}> {item.Contact.Data.Email.Title} </span>
                      <p> {item.Contact.Data.Email.Description} </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            {/* Lottie */}
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              <div className={classes.container}>
                <img className={classes.borderLottie} src={item.Footer.BackgroundLottie} alt="Micho Logo"></img>
                <div className={classes.lottie_container}>
                  <Lottie options={{ animationData: LottieFooter, ...defaultOptions }} speed={1.5} />
                </div>
                <img className={classes.mexicoMap} src={item.Footer.Map} alt="Map Icon"></img>
              </div>
            </Grid>
          </Grid>
        ))}

        {/* <div>
          <p>qwert</p>
        </div> */}
      </Container>
      <label style={{ fontSize: 9, color: "#fff" }}>V {version}</label>
    </div>
  );
};

export default Footer;
