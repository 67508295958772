import React from 'react'; 
import { Grid } from '@material-ui/core';

import OvalA from '../../../media/images/oval_a.svg';
import OvalB from '../../../media/images/oval_b.svg';
import IconBag from '../../../media/icons/ic_bag.svg';
import IconSearch from '../../../media/icons/ic_search.svg';
import { Colors } from '../../../values';

import { makeStyles } from '@material-ui/core/styles';
import { formatMoney } from '../../../utils/BaseBuilder';
import './../../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    content:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 50,

    },

    section:{
        transition: '0.3s',
        width: 250,
        height: 250,
        borderRadius: '50%',
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover':{
            transition: '0.3s'
        }
        ,'&:hover $ovalUnselected':{
            opacity: 0,
            transition: '0.3s'
        }
        ,'&:hover $ovalSelected':{
            opacity: 1,
            transition: '0.3s'
        }
        ,'&:hover $productImg':{
            transition: '0.3s',
            transform: 'scale(0.80)'
        }
        ,'&:hover $sectionIcons':{
            opacity: 1,
            transition: '0.3s'
        }
    },

    ovalUnselected: {
        backgroundImage: `url(${ OvalA })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 250px',
        width: 250,
        height: 250,
        opacity: 1,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            backgroundSize: '225px 225px',
            width: 225,
            height: 225,
        },
        [theme.breakpoints.only('xs')]:{
            backgroundSize: '250px 250px',
            width: 250,
            height: 250,
        }
    },

    ovalSelected: {
        backgroundImage: `url(${ OvalB })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 250px',
        width: 250,
        height: 250,
        opacity: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            backgroundSize: '225px 225px',
            width: 225,
            height: 225,
        },
        [theme.breakpoints.only('xs')]:{
            backgroundSize: '250px 250px',
            width: 250,
            height: 250,
        }
    },

    productImg: {
        width: 185,
        height: 185,
        position:'absolute',
        [theme.breakpoints.down('md')]:{
            backgroundSize: '225px 225px',
            width: 150,
            height: 150,
        },
        [theme.breakpoints.only('xs')]:{
            width: 185,
            height: 185,
        }
    },

    sectionIcons: {
        position: 'absolute',
        opacity: 0,
        transition: '0.3s',
        marginTop: 150,
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    icon:{
        backgroundPosition: 'center',
        backgroundSize: '50px 50px', 
        backgroundRepeat: 'no-repeat',
        background: 'black',
        height: 50, 
        width:50,
        border: 'none',
        borderRadius: '50%',
        margin: 10,
        cursor: 'pointer',
        '&:focus':{
            outline: 'none'
        }
        

    },

}))

const Product = ({ idProduct, imageProduct, altProduct ,nameProduct, descriptionProduct, priceProduct, productCategory, categorySelect ,handleOpenDialog, add }) => {
    const classes = useStyles();
    return(
        <Grid item lg={4} md={4} sm={6} xs={12} >
            <div className={ classes.content }>
                <div className={classes.section }>
                    <div className={ classes.ovalUnselected} >
                        <img className={ classes.productImg } src={ imageProduct } alt=''></img>
                    </div>

                    <div className={ classes.ovalSelected} >
                        <img className={ classes.productImg } src={ imageProduct } alt=''></img>
                        <div className={ classes.sectionIcons }>
                            <button className={ classes.icon } style={{ backgroundImage: `url(${ IconSearch })`}} onClick={() => handleOpenDialog( idProduct ) }></button>
                            <button className={ classes.icon } style={{ backgroundImage: `url(${ IconBag })`}} onClick={() => add( idProduct ) }></button>
                        </div>
                    </div>
                </div>
                <div style={{ width: 250, display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <span style={{ fontFamily: 'Heavy', fontSize: 20, fontWeight: 'bold'}}> { nameProduct }</span>
                    <br></br>
                    <span style={{ fontFamily: 'Book', fontSize: 15, fontWeight: 'bold', color: Colors.gray }}>$ {formatMoney(priceProduct)} MXN</span>
                </div>
            </div>
        </Grid>
    );
}

export default Product;