import React, { useState } from 'react';
import { Drawer, List, ListItem, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; 
import { Colors, menuItem } from '../../values';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import '../../fonts/font.css';

const useStyles = makeStyles(theme => ({
    list: {
        width: 220,
    },

    paper:{
        background: 'firebrick'
    }
}))
const DrawerMenu = () => {
    const classes = useStyles()
    const [state, setState] = useState({ right: false });

    const toggleDrawer = ( side, open ) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                { menuItem.map((item) => 
                    (
                        <ListItem button key={item.id} style={{paddingTop:10, paddingBottom:10, paddingRight:0, paddingLeft:0}}>
                            <Button style={{color:'white', width:'100%', padding:0, paddingTop:10, paddingBottom:10 }} key={item.id} to={`/${item.title.toLowerCase()}`} component={RouterLink}>
                                <span style={{textAlign:'left', width:'100%', paddingLeft:25, fontFamily:'Move'}}>{item.title}</span>
                            </Button>
                        </ListItem>
                    )
                )}
            </List>
        </div>
    );

    return(
        <div>
            <IconButton onClick={toggleDrawer('right', true)}>
                <MenuIcon fontSize='large' style={{ color: Colors.black }} />
            </IconButton>

            <Drawer anchor='right' open={ state.right } onClose={toggleDrawer('right', false)} classes={{paper: classes.paper}}>
                { sideList('right') }
            </Drawer>
        </div>
    )
}

export default DrawerMenu;