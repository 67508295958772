import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";

import Main from "../global/Main";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "10px 35px",
    border: "1px solid #c0c0c080",
    borderRadius: 10,
    margin: "50px 0",
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
}));

const PrivacyPolicies = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Fragment>
      <div style={{ position: "relative", marginBottom: 50 }}>
        <Main
          key={""}
          bgColor1={"#42B748"}
          bgColor2={"#349239"}
          title="Politicas de Privacidad"
          icon="https://firebasestorage.googleapis.com/v0/b/dulces-micho.appspot.com/o/Demo%2Ficon_company.png?alt=media&amp;token=ee6bba33-7b4b-4c68-9972-dfc1f13c0bf7"
          widthImg="125px"
        />
      </div>
      <Container>
        <p>
          Dulces Micho, S.A. de C.V. mejor conocido como Dulces Micho, con el portal de https://dulcesmicho.com/, es el
          responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
        </p>
        <p>¿Para qué fines utilizaremos sus datos personales?</p>
        <p>
          Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son
          necesarias para el servicio que solicita:
        </p>
        <p>Para poner a su disposición los productos de Dulces Micho.</p>
        <p>Para integrarla dentro de nuestra base de datos pública de distribuidores autorizados</p>
        <p>
          Además, para personalizar nuestros servicios, mejorar su experiencia y mantener una comunicación efectiva.
        </p>
        <p>
          De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no
          son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
        </p>
        <p>Mercadotecnia o publicitaria</p>
        <p>Prospección comercial</p>
        <p>
          En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a
          continuación envié un correo a info@dulcesmicho.com La negativa para el uso de sus datos personales para estas
          finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con
          nosotros.
        </p>
        <p>¿Qué datos personales utilizaremos para estos fines?</p>
        <p>
          Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes
          datos personales:
        </p>
        <p>Datos de identificación y/o contacto: nombre, domicilio, fecha de nacimiento, correo electrónico.</p>
        <p>Datos bancarios.</p>
        <p>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</p>
        <p>
          Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones
          del uso que les damos (Acceso).
        </p>
        <p>
          Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada,
          sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando
          considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus
          datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
        </p>
        <p>
          Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través
          del siguiente medio:
        </p>
        <p>Enviando una solicitud al correo electrónico info@dulcesmicho.com</p>
        <p>
          Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:
        </p>
        <p>
          a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como
          la personalidad este último?
        </p>
        <p>Por medio del correo electrónico info@dulcesmicho.com</p>
        <p>b) ¿Qué información y/o documentación deberá contener la solicitud?</p>
        <p>1.El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</p>
        <p>2.Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</p>
        <p>
          3.La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los
          derechos antes mencionados;
        </p>
        <p>
          4.Cualquier otro elemento o documento que facilite la localización de los datos controvertidos, y las
          modificaciones a realizarse; y
        </p>
        <p>5.Aportar la documentación que sustente su petición.</p>
        <p>c) ¿En cuántos días le daremos respuesta a su solicitud?</p>
        <p>15 días hábiles.</p>
        <p>d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</p>
        <p>Por medio del correo electrónico info@dulcesmicho.com</p>
        <p>
          e) Para mayor información sobre el procedimiento, ponemos a disposición la siguiente pagina web:
          https://dulcesmicho.com/privacy/deleteAccount
        </p>
        <p>
          Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las
          solicitudes de derechos ARCO, son los siguientes:
        </p>
        <p>Departamento de recepción de información. - Correo electrónico: info@dulcesmicho.com</p>
        <p>Usted puede revocar o limitar su consentimiento para el uso de sus datos personales</p>
        <p>
          Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos
          personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su
          solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos
          seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación
          de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la
          conclusión de su relación con nosotros.
        </p>
        <p>Para revocar o limitar su consentimiento deberá presentar su solicitud a través del siguiente medio:</p>
        <p>Correo electrónico info@dulcesmicho.com</p>
        <p>
          Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo
          siguiente:
        </p>
        <p>
          a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como
          la personalidad este último?
        </p>
        <p>Por medio del correo electrónico info@dulcesmicho.com.</p>
        <p>b) ¿Qué información y/o documentación deberá contener la solicitud?</p>
        <p>1. El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</p>
        <p>2. Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</p>
        <p>
          3.La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los
          derechos antes mencionados;
        </p>
        <p>
          4. Cualquier otro elemento o documento que facilite la localización de los datos controvertidos, y las
          modificaciones a realizarse; y
        </p>
        <p>5. Aportar la documentación que sustente su petición.</p>
        <p>c) ¿En cuántos días le daremos respuesta a su solicitud?</p>
        <p>15 días hábiles.</p>
        <p>d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?</p>
        <p>Por medio del correo electrónico info@dulcesmicho.com</p>
        <p>
          e) Para más información sobre el procedimiento, ponemos a disposición la siguiente página web:
          info@dulcesmicho.com
        </p>
        <p>¿Cómo puede conocer los cambios en este aviso de privacidad?</p>
        <p>
          El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
          requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de
          nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
        </p>
        <p>
          Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a
          través de este medio.
        </p>
        <p>Su consentimiento para el tratamiento de sus datos personales</p>
        <p>
          Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en
          el presente aviso de privacidad.
        </p>

        <div className={classes.card}>
          <Container>
            <p style={{ fontWeight: "bold", fontFamily: "Phosphate", fontSize: 32, margin: 0 }}>
              ELIMINAR MI CUENTA Y <br /> DATOS ASOCIADOS
            </p>
            <p>Solicita la eliminacion de tu cuenta de nuestra plataforma y los datos asociados.</p>

            <div style={{ width: "100%", maxWidth: 450 }}>
              <Button
                onClick={() => history.push("/delete_account")}
                style={{ backgroundColor: "#D72E26", color: "#fff", width: "100%", fontFamily: "Book" }}
              >
                CREAR SOLICITUD
              </Button>
            </div>
          </Container>
        </div>
      </Container>
    </Fragment>
  );
};

export default PrivacyPolicies;
