import React from 'react';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import './../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    root:{
        marginTop: 125,
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]:{
            marginTop: 50,
        }
    },

    message: {
        fontFamily: 'Heavy',
        fontSize: 35,
        color: Colors.gray,
        textAlign: 'center'
    },

    displayButtonBack: {
        display: 'flex',
        justifyContent: 'center',
        padding: 5,
        background: 'firebrick',
        width: 275,
        borderRadius: 10
    },

    buttonBack: {
        animation: `$pulsate-fwd 1.5s ease-in-out infinite both`,
        },
        '@keyframes pulsate-fwd': {
            '0%': {
                transform: 'scale(1)',
            },
            '50%': {
                transform: 'scale(1.1)',
            },
            '100%': {
                transform: 'scale(1)',
            }
        }

}));

const EmptyCart = ({ openCart, setOpenCart }) => {
    const classes = useStyles()
    return(
        <div className={ classes.root }>
            <p className={ classes.message } > Tu carrito se encuentra actualmente vacio </p>
            <div className={ classes.displayButtonBack }>
                <Button
                    style={{ background: 'firebrick', color: Colors.white, fontSize: 20, fontFamily:'Heavy', width: '85%' }}
                    onClick= { e => {setOpenCart(!openCart) }}
                    className={classes.buttonBack}
                >
                    Ir a productos
                </Button>
            </div>
        </div>
    );
}

export default EmptyCart;
