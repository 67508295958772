import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            marginTop: 50
        }
    }
}))

const Client = ({ img }) => {
    const classes = useStyles();

    return(
        <div className = { classes.container }>
            <img style={{ width:200 }} src={ img } alt='Logo'></img>
        </div>
    )
}
export default Client; 