import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Input } from "@material-ui/core";

import { deleteAgentStep1, deleteAgentsStep2 } from "../../utils/BaseBuilder";

import appIcon from "../../media/images/appIcon.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "35px 0px",
    border: "1px solid #c0c0c080",
    borderRadius: 10,
    width: "100%",
    maxWidth: 640,
    position: "relative",
    marginTop: 50,
    marginBottom: 150,
    [theme.breakpoints.down("md")]: {
      padding: "20px 10px",
      width: "auto",
    },
  },
  input: {
    border: "1px solid #c0c0c080",
    width: "100%",
    marginBottom: 20,
    padding: 5,
  },
}));

const Charging = () => {
  return (
    <div>
      <p style={{ margin: 0, color: "#fff" }}>PROCESANDO...</p>
    </div>
  );
};

const AccountDeleted = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <p style={{ fontFamily: "Gochi", color: "#fff", fontSize: 62, marginBottom: 0 }}>CUENTA ELIMINADA</p>
      <Container maxWidth="md">
        <div style={{ padding: "0 100px" }}>
          <p style={{ fontFamily: "Book", color: "#fff" }}>
            Tu cuenta se ha desabilitado, los datos asociados serán borrados en los siguientes 90 días. Para cualquier
            duda, puedes contactarnos en el siguiente correo info@dulcesmicho.com
          </p>
        </div>
      </Container>
    </div>
  );
};

const DeleteAccount = () => {
  const { id } = useParams();
  const classes = useStyles();

  const [status, setStatus] = useState(0);
  const [email, setEmail] = useState("");
  const [loading1, setLoading1] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading1(true);

    const res = await deleteAgentStep1(email);
    if (res) {
      toast.success("Se te envío un corrreo para proceder a eliminar tu cuenta");
    } else {
      toast.error("Ocurrió un error vuelve a intentarlo.");
    }

    setLoading1(false);
  };

  const handleDelete = async () => {
    const res = await deleteAgentsStep2(id);
    if (res) {
      setStatus(2);
    } else {
      toast.error("Ocurrió un error vuelve a intentarlo.");
    }
  };

  useEffect(() => {
    if (id) {
      setStatus(1);
      const delayTyping = setTimeout(() => {
        handleDelete();
      }, 2500);
      return () => clearTimeout(delayTyping);
    }
  }, [id]);

  return (
    <Fragment>
      {status === 0 ? (
        <Fragment>
          <div style={{ position: "relative", background: "#D72E26", marginBottom: 40 }}>
            <Container>
              <p style={{ fontSize: 62, fontFamily: "Gochi", paddingTop: 30, margin: 0, color: "#fff" }}>
                ELIMINAR MI CUENTA
              </p>
              <p style={{ color: "#fff", margin: 0, paddingBottom: 40 }}>
                Solicita la eliminación de tu cuenta de nuestra plataforma y los datos asociados.
              </p>
            </Container>
          </div>
          <Container>
            <div
              style={{ background: "#D2D2D2", borderRadius: 20, maxWidth: 500, display: "flex", alignItems: "center" }}
            >
              <img alt="" src={appIcon} style={{ width: 140 }} />
              <label style={{ fontWeight: 600 }}>Dulces Micho Agentes</label>
            </div>
            <p style={{ fontWeight: 500, margin: "20px 0", paddingBottom: 10 }}>
              Perderás también acceso a la aplicación Dulces Micho Agentes.
            </p>

            <p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>IMPORTANTE</p>
            <p style={{ margin: 0 }}>Perderás todos los datos y contenido que se ha creado con tu cuenta.</p>
            <p style={{ margin: 0, paddingBottom: 30 }}>
              Ya no tendrás acceso a los servicios y aplicaciones dónde firmas con tu cuenta para acceder.
            </p>

            <p style={{ fontWeight: 600, fontSize: 18, marginBottom: 0 }}>PASOS </p>
            <p style={{ margin: 0 }}>
              Para eliminar tu cuenta será necesario que ingreses el correo con el que estás registrado en nuestra
              plataforma.
            </p>
            <p style={{ margin: 0 }}>Enviaremos un correo para validar tu solicitud.</p>
            <p style={{ margin: 0 }}>Haz clic en el link que está dentro del correo.</p>
            <p style={{ margin: 0 }}>
              Tu cuenta será deshabilitada y en un plazo de 90 días tus datos serán eliminados de la plataforma.
            </p>

            <div className={classes.card}>
              <form onSubmit={handleSubmit}>
                <Container>
                  <Input
                    className={classes.input}
                    placeholder="EMAIL"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#D72E26", color: "#fff", width: "101%", fontFamily: "Book" }}
                  >
                    {loading1 ? "Cargando..." : "ENVIAR"}
                  </Button>
                </Container>
              </form>
            </div>
          </Container>
        </Fragment>
      ) : (
        <div style={{ background: "#D72E26", marginTop: 80 }}>
          <Container>
            <div style={{ height: 520, display: "flex", justifyContent: "center", alignItems: "center" }}>
              {status === 1 ? (
                <div onClick={() => setStatus(2)}>
                  <Charging />
                </div>
              ) : (
                <div onClick={() => setStatus(0)}>
                  <AccountDeleted />
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default DeleteAccount;
