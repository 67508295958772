import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({

    container:{
        [theme.breakpoints.down('md')]:{
            marginBottom: 50
        }
    },

    content:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 150,
        [theme.breakpoints.down('md')]:{
            marginBottom: 20
        }
    },

    img: {
        width: 200,
        height: 200,
        cursor: 'pointer',
        '&:hover':{
            transition: '0.3s',
            transform: 'scale(0.90)'
        },
        [theme.breakpoints.down('md')]:{
            width: 200,
            height: 200,
        }
    },

    textRecipe:{
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Gochi',
        [theme.breakpoints.down('md')]:{
            fontSize: 25,
            textAlign: 'center'
        }
    }
}))

const RecipeSingle = ({ image, title }) => {
    const classes = useStyles()
    return(
        <Grid item xl={4} lg={4} xs={12}>
            <div className={classes.container}>
                <div className={classes.content}>
                    <Button style={{ background: Colors.white }} to={'/recetas'} component={ RouterLink } >
                        <img className={ classes.img } src={ image } alt={title}></img>
                    </Button>
                    <span className={ classes.textRecipe }>{ title }</span>
                </div>
            </div>
        </Grid>
    )
}

export default RecipeSingle