import React from 'react';
import NavBar from './NavBar';

const Menu = ({ baseContent }) => {
    return(
        <div style={{ top:0, width:'100%', zIndex: 4 }}>
            {/* <div style={{background:'red', display:'flex', justifyContent:'center'}}>
                <label style={{color:'#fff',paddingTop:5, paddingBottom:5}}>Pedidos Realizados del 27 de marzo al 4 de abril, seran enviados a partir del 5 de abril</label>
            </div> */}
            <NavBar baseContent={baseContent} />
        </div>
    )
}

export default Menu;