import React from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Colors } from '../../../values';
import { makeStyles } from '@material-ui/core/styles';
import { categorySelected } from '../../../redux/actions/Cart';
import { Link as RouterLink } from 'react-router-dom';
import './../../../fonts/font.css';

const useStyles= makeStyles(theme => ({

    section:{
        width: '100%',
        background: '#00000000',
        transition: '0.3s',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover':{
            transition: '0.3s'
        }
        ,'&:hover $sectionImage':{
            transform: 'scale(1.2)',
            transition: '0.3s'
        }
        ,'&:hover $sectionBackground':{
            transition: '0.3s',
            // background: 'linear-gradient(0deg, rgba(255, 169, 0,1) 0%, rgba(255, 169, 0,1) 100%) '
        }
        ,'&:hover $sectionTitle':{
            marginTop: 500,
            transition: '0.3s'
        }
        ,'&:hover $sectionMessage':{
            opacity: 1,
            transition: '0.3s'
        }
    },

    sectionImage:{
        transform: 'scale(1.0)',
        transition: '0.3s',
    },

    sectionBackground: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        transition: '0.3s'
    },

    sectionTitle:{
        position: 'absolute',
        width: '80%',
        height: 'auto',
        marginTop: '100%',
        marginLeft: 50,
        marginRight: 50,
        transition: '0.3s',
    },

    sectionMessage: {
        opacity: 0,
        transition: '0.3s',
        width: '70%',
        marginRight: '40%',
    }
}))

const Product = ({ image, title, categorySelected, Category }) => {
    const classes = useStyles()
    
    return(
        <Button className={ classes.section } 
            style={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}} 
            onClick={ () => categorySelected(title) }
            to={'productos'}
            component={RouterLink}
        >
            <div className= { classes.sectionBackground } />
            <img className={ classes.sectionImage } src={ image } alt='producto'></img>
            <div style={{ position:'absolute', bottom:0, width:'100%', height: 600 }}>
                <div className={ classes.sectionTitle }>
                    <span className={ classes.sectionMessage } style={{ color:Colors.black, fontSize: 50, fontFamily:'Phosphate' }}>{ title }</span>
                </div>
            </div>
        </Button>
    );
}

const mapStateProps = (state) => ({
    Category:       state.Cart.category
})

export default connect(mapStateProps, { categorySelected })(Product);