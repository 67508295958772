import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    container: {
        background: '#742071',
        color: Colors.white,
        borderRadius: 20,
        height: 350,
        width: 300
    },

    textDesc: {
        fontFamily:'Book', 
        whiteSpace:'pre-line', 
        lineHeight: 0.7,
        color: Colors.white,
        fontSize: 14,
    },

}))

const About = ({ locationIcon, locationTitle, locationDesc, 
    phoneIcon, phoneTitle, phoneDesc, 
    emailIcon, emailTitle, emailDesc }) => {
    const classes = useStyles();

    return(
        <div className={ classes.container }>
            <div style={{ height:'100%', display:'flex', alignItems:'center', justifyContent: 'center' }}>
                <div>

                    {/* Location Info */}
                    <div className={ classes.textDesc }>
                        <div style={{ display: 'flex', alignItems:'center', height:'100%' }}>
                            <img style={{ width: 20 }} src={ locationIcon } alt='Location Icon'></img>
                            <span style={{ marginLeft:10, fontWeight: 'bold', fontFamily: 'Heavy'}}>{ locationTitle }</span>
                        </div>
                        <p> { locationDesc } </p>
                        <br/>
                    </div>
                    {/* Phone info */}
                    <div className={ classes.textDesc }>
                        <div style={{ display: 'flex', alignItems:'center', height:'100%' }}>
                            <img style={{ width: 20 }} src={ phoneIcon } alt='Location Icon'></img>
                            <span style={{ marginLeft:10, fontWeight: 'bold', fontFamily: 'Heavy'}}> { phoneTitle } </span>
                        </div>
                        <p> { phoneDesc } </p>
                        <br/>
                    </div>

                    {/* Email info */}
                    <div className={ classes.textDesc }>
                        <div style={{ display: 'flex', alignItems:'center', height:'100%' }}>
                            <img style={{ width: 20 }} src={ emailIcon } alt='Location Icon'></img>
                            <span style={{ marginLeft:10, fontWeight: 'bold',fontFamily: 'Heavy'}}> { emailTitle } </span>
                        </div>
                        <p> { emailDesc } </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About