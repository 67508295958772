import React from 'react';
import Form from './Form';
import About from './About';

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    content: {
        marginTop: 100,
        padding: 0,
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
            marginBottom: 30
        }
    },

    container: {
        background: '#E9D4E8',
        height: 650,
        [theme.breakpoints.down('md')]: {
            background: Colors.white,
            height: '100%'
        }
    },

    displayForm: {
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        }
    },

    displayAbout: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: 65,
        marginLeft: -40,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            position: 'relative',
            marginLeft: 0,
            marginTop: 10
        }
    }
}))

const Contact = ({ title, nameLabel, emaiLabel, messageLabel, send,
    locationIcon, locationTitle, locationDesc,
    phoneIcon, phoneTitle, phoneDesc,
    emailIcon, emailTitle, emailDesc }) => {
    const classes = useStyles()
    return (
        <div>
            <Container maxWidth={false} className={classes.content}>
                <div className={classes.container}>
                    <Grid container style={{ height: '100%' }}>
                        <Grid item xl={1} lg={2} xs={1} />

                        <Grid item xl={10} lg={9} xs={10} >
                            <Grid container style={{ height: '100%' }}>
                                <Grid item xl={7} lg={7} md={12} xs={12}>
                                    <div className={classes.displayForm}>
                                        <Form
                                            title={title}
                                            nameLabel={nameLabel}
                                            emaiLabel={emaiLabel}
                                            messageLabel={messageLabel}
                                            send={send}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xl={5} lg={5} md={12} xs={12} >
                                    <div className={classes.displayAbout} >
                                        <About
                                            locationIcon={locationIcon}
                                            locationTitle={locationTitle}
                                            locationDesc={locationDesc}

                                            phoneIcon={phoneIcon}
                                            phoneTitle={phoneTitle}
                                            phoneDesc={phoneDesc}

                                            emailIcon={emailIcon}
                                            emailTitle={emailTitle}
                                            emailDesc={emailDesc}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={1} lg={1} xs={1} />
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Contact;