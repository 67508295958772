import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { getProducts, categorySelected, sendSale } from "../../redux/actions/Cart";

import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ScrollMenu from "react-horizontal-scrolling-menu";
import MainCart from "./components/MainCart";
import Categories from "./components/Categories";
import ProductSingle from "./components/ProductSingle";
import Cart from "./components/Cart";
import Dialog from "./components/Dialog";
import EmptyCart from "./components/EmptyCart";

import { Animated } from "react-animated-css";
import { formatMoney } from "../../utils/BaseBuilder";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  displayMenuDesktop: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 280,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },

  displayMenuMobile: {
    // display: 'none',
    [theme.breakpoints.down("lg")]: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const Products = ({
  dataCategories,
  dataProducts,
  getProducts,
  categorySelected,
  Products,
  Category,
  sendSale,
  Sale,
}) => {
  const classes = useStyles();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [open, setOpen] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [productCurrent, setProductCurrent] = useState({});

  const brand = searchParams.get("brand");
  const id = searchParams.get("id");

  const handleOpenDialog = (productID) => {
    try {
      const product = dataProducts.find((product) => product.Id === productID);
      product.Quantity = 1;
      product.Subtotal = formatMoney(product.PublicPrice);
      setProductCurrent(product);
      setOpen(true);
    } catch (error) { }
  };

  const handleOpenDialog2 = (productID) => {
    // console.log("dataProducts: ", dataProducts);
    const product = dataProducts.find((product) => product.Id === productID);
    product.Quantity = 1;
    product.Subtotal = formatMoney(product.PublicPrice);
    return product;
  };

  const addMoreProductsQuantity = (productID) => {
    const validateFilter = Products.find((prod) => prod.Id === productID);
    if (validateFilter === undefined) {
      const product = handleOpenDialog2(productID);
      getProducts([...Products, product]);
      toast.success("Producto agregado al carrito con exito");
    } else {
      let array = [];
      let prodFilter = Products.find((prod) => prod.Id === productID);
      const auxProduct = prodFilter;
      const Quantity = auxProduct.Quantity + 1;
      const Subtotal = getSubTotal(auxProduct.PublicPrice, Quantity);
      auxProduct.Subtotal = Subtotal;
      auxProduct.Quantity = Quantity;

      Products.forEach((element) => {
        if (element.Id !== productID) {
          array.push(element);
        }
      });

      array.push(auxProduct);
      getProducts(array);
      toast.success("Producto agregado al carrito con exito");
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Add Products
  const handleAddQuantity = () => {
    const Quantity = productCurrent.Quantity >= 9999 ? 9999 : productCurrent.Quantity + 1;

    const Subtotal = getSubTotal(productCurrent.PublicPrice, Quantity);
    const update = { Quantity, Subtotal };
    // console.log("UPDATE: ", update);
    setProductCurrent({ ...productCurrent, ...update });
  };

  // Substract products
  const handleLessQuantity = () => {
    const Quantity = productCurrent.Quantity <= 1 ? 1 : productCurrent.Quantity - 1;

    const Subtotal = getSubTotal(productCurrent.PublicPrice, Quantity);
    const update = { Quantity, Subtotal };

    setProductCurrent({ ...productCurrent, ...update });
  };

  const handleValueQuantity = (value) => {
    const Subtotal = getSubTotal(productCurrent.PublicPrice, parseFloat(value));
    const update = { Quantity: parseFloat(value), Subtotal };
    setProductCurrent({ ...productCurrent, ...update });
  }

  const getSubTotal = (priceProduct, Quantity) => {
    return priceProduct * Quantity;
  };

  const handleAddCart = (e) => {
    e.preventDefault()
    if (Products.length <= 0) {
      getProducts([...Products, productCurrent]);
      // console.log('Carrito: ', Products)
    } else {
      const preventDuplicate = findProductCart();
      if (preventDuplicate) {
        const previewCart = [...Products];
        previewCart[preventDuplicate.key].Quantity += parseFloat(productCurrent.Quantity);
        const Quantity = previewCart[preventDuplicate.key].Quantity;
        const Subtotal = getSubTotal(productCurrent.PublicPrice, Quantity);
        previewCart[preventDuplicate.key].Subtotal = Subtotal;

        getProducts(previewCart);
        // console.log('Carrito: ', Products)
      } else {
        getProducts([...Products, productCurrent]);
        // console.log('Carrito: ', Products)
      }
    }

    toast.success("Producto agregado al carrito con exito");
    handleCloseDialog();
  };

  const findProductCart = () => {
    return Products.find((product, key) => {
      if (product.Id === productCurrent.Id) {
        product.key = key;
        return product;
      }
      return null;
    });
  };

  useEffect(() => {
    if (dataProducts.length !== 0 && brand && id) {
      categorySelected(brand);
      let timer = setTimeout(() => handleOpenDialog(id), 1200);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [brand, dataProducts]);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <MainCart setOpenCart={setOpenCart} openCart={openCart} />
      </div>

      <Container maxWidth="xl">
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={!openCart}
          style={{ display: !openCart ? "block" : "none" }}
        >
          <div className={classes.displayMenuMobile}>
            <ScrollMenu
              alignCenter={true}
              data={dataCategories.map((item, index) => (
                <Categories key={index} icon={item.Image} name={item.Name} />
              ))}
            />
          </div>
        </Animated>
      </Container>
      <Container style={{ padding: 0, marginBottom: 150 }} maxWidth="lg">
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          isVisible={!openCart}
          style={{ display: !openCart ? "block" : "none" }}
        >
          <Grid container style={{ flexGrow: 1 }} justify="center" spacing={0}>
            {dataProducts
              .filter((product) => product.Category === Category)
              .map((product) => (
                <ProductSingle
                  key={product.Id}
                  idProduct={product.Id}
                  imageProduct={product.Image}
                  nameProduct={product.Name}
                  descriptionProduct={product.Description}
                  priceProduct={product.PublicPrice}
                  productCategory={product.Category}
                  categorySelect={Category}
                  altProduct={product.Name}
                  handleOpenDialog={handleOpenDialog}
                  add={addMoreProductsQuantity}
                />
              ))}
          </Grid>
        </Animated>

        <Animated
          animationIn="fadeIn"
          animationInDuration={2500}
          animationOut="fadeOut"
          animationOutDuration={2500}
          isVisible={openCart}
          style={{ display: openCart ? "block" : "none" }}
        >
          {Products.length !== 0 ? (
            <Cart setOpenCart={setOpenCart} openCart={openCart} />
          ) : (
            <EmptyCart setOpenCart={setOpenCart} openCart={openCart} />
          )}
        </Animated>

        <Dialog
          open={open}
          handleClose={handleCloseDialog}
          nameProduct={productCurrent.Name}
          imageProduct={productCurrent.Image}
          descriptionProduct={productCurrent.Description}
          quantity={productCurrent.Quantity}
          handleLessQuantity={handleLessQuantity}
          handleAddQuantity={handleAddQuantity}
          handleValueQuantity={handleValueQuantity}
          handleAddCart={handleAddCart}
          subTotal={productCurrent.Subtotal}
        />
      </Container>
    </div>
  );
};

const mapStateProps = (state) => ({
  Products: state.Cart.products,
  Category: state.Cart.category,
});

const mapDispatchToProps = { getProducts, categorySelected, sendSale };

export default connect(mapStateProps, mapDispatchToProps)(Products);
