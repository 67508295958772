import React from 'react';
import { Grid } from '@material-ui/core';
import Main from './components/Main';
import Touch from './components/Touch';
import Products from './components/Products';
import Banner from './components/Banner'; 
import BannerB from './components/BannerB';
import RecipeSingle from './components/RecipeSingle';

const Home = ({ baseContent, dataRecipes }) => {
    return(
        <div>
            { baseContent.map((item,index) => 
                <div key={index}>
                    <div style={{ position: 'relative' }}>
                        <Main 
                            key             = { item.id }
                            background      = { item.Home.Main.Background }
                            banner_img      = { item.Home.Main.Image }
                            title           = { item.Home.Main.Title }
                            subTitle        = { item.Home.Main.SubTitle }
                            iconFB          = { item.Home.Main.IconFb }
                            linkFb          = { item.Home.Main.LinkFb }
                            iconIG          = { item.Home.Main.IconIg }
                            linkIg          = { item.Home.Main.LinkIg }
                            iconGame        = { item.Home.Main.IconGame }
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Touch 
                            title           = { item.Home.Touch.Title }
                            subTitle        = { item.Home.Touch.Subtitle }
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Products
                            imgProduct1  = { item.Home.ProductsA[index].Image }
                            text1        = { item.Home.ProductsA[index].Category}
                            imgProduct2  = { item.Home.ProductsA[index + 1].Image }
                            text2        = { item.Home.ProductsA[index + 1].Category}
                            imgProduct3  = { item.Home.ProductsA[index + 2].Image }
                            text3        = { item.Home.ProductsA[index + 2].Category}
                            background1  = {'#69C33D'}
                            background2  = {'#81CB35'}
                            background3  = {'#9DD32C'} 
                        />
                    </div> 

                    <div style={{ position: 'relative' }}>
                        <Banner 
                            imageA = { item.Home.Banner.ImageA }
                            imageB = { item.Home.Banner.ImageB }
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Products
                            imgProduct1 = { item.Home.ProductsB[index].Image }
                            text1       = { item.Home.ProductsB[index].Category}
                            imgProduct2 = { item.Home.ProductsB[index + 1].Image }
                            text2       = { item.Home.ProductsB[index + 1].Category}
                            imgProduct3 = { item.Home.ProductsB[index + 2].Image }
                            text3       = { item.Home.ProductsB[index + 2].Category}
                            background1  = {'#EFEE15'}
                            background2  = {'#CDE31E'}
                            background3  = {'#B4DB26'} 
                        />
                    </div> 

                    <div style={{ position: 'relative' }}>
                        <BannerB 
                            background  = { item.Home.BannerB.Background }
                            over        = { item.Home.BannerB.Over}      
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Products
                            imgProduct1 = { item.Home.ProductsC[index].Image }
                            text1       = { item.Home.ProductsC[index].Category }
                            imgProduct2 = { item.Home.ProductsC[index + 1].Image }
                            text2       = { item.Home.ProductsC[index + 1].Category }
                            imgProduct3 = { item.Home.ProductsC[index + 2].Image }
                            text3       = { item.Home.ProductsC[index + 2].Category }
                            background1  = {'#CDE31E'}
                            background2  = {'#B4DB26'}
                            background3  = {'#9DD32C'} 
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Touch  
                            title       = {item.Home.Recipes.Title}
                            subTitle    = {item.Home.Recipes.Subtitle}
                        />
                    </div>

                    <Grid container>
                        { dataRecipes.map((item, index) => 
                            <RecipeSingle
                                key={index}
                                image = { item.Image }
                                title = { item.Name }
                            />
                        )}
                    </Grid>
                </div>
            )}
        </div>
    );
}

export default Home;