import React from "react";
import Lottie from "react-lottie";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../values";
import Background from "../../media/images/background.svg";
import Logo from "../../media/animations/logo.json";

const useStyles = makeStyles((theme) => ({
  pattern_bg: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  logo_container: {
    height: 350,
    width: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",

    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  // Animation of intro
  slide_top: {
    background: "linear-gradient(0deg, rgba(206,227,30,1) 0%, rgba(68,183,71,1) 100%)",
    position: "relative",
    zIndex: 1,
    width: "100vw",
    height: "100vh",
    // animation: `$slide_top 3.5s ease`,
    // animationDelay: "1.5s",
  },
  "@keyframes slide_top": {
    "0%": {
      opacity: 1,
      // transform: 'translateY(0)'
    },

    "100%": {
      opacity: 0,
      // transform: 'translateY(-100%)'
    },
  },
}));

const Intro = () => {
  const classes = useStyles();

  const defaultOptions = {
    speed: 0.1,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    // <div className={classes.slide_top}>
    //   <div className={classes.pattern_bg}>
    //     <div className={classes.logo_container}>
    //       <Lottie options={{ animationData: Logo, ...defaultOptions }} height={300} width={300} />
    //     </div>
    //   </div>
    // </div>
    <div className={classes.slide_top}>
      {/* <img
        alt=""
        src={Background}
        style={{ width: "100%", height: "100%", objectFit: "cover", position: "absolute" }}
      /> */}

      <div className={classes.logo_container}>
        <Lottie options={{ animationData: Logo, ...defaultOptions }} height={300} width={300} />
      </div>
    </div>
  );
};

export default Intro;
