import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    container: {
        border: `1px solid ${Colors.borderGray}`,
        borderRadius: 20,
        marginBottom: 50,
        paddingTop: 20,
        paddingBottom: 20
    },

    container_img:{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    container_text:{
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    title: {
        color: Colors.black,
        fontSize: 40,
        fontFamily: 'Phosphate',
        [theme.breakpoints.down('md')]:{
            fontSize: 35,
            textAlign: 'center'
        }
    },

    text: {
        fontSize: 20,
        fontFamily: 'Book',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('md')]:{
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 15,
            textAlign: 'justify'
        }
    },

    textRecipeDisplay: {
        paddingRight: 50,
        [theme.breakpoints.down('md')]:{
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }

    }

}))
const Recipe = ({ title, img, text }) => {
    const classes = useStyles()

    return(
        <Grid item xl={12} lg={12} xs={12}>
            <Grid container >
                <Grid item xl={1} lg={1} xs={1} />
                
                <Grid item xl={10} lg={10} xs={10} className={ classes.container }>
                    <Grid container>
                        <Grid item xl={3} lg={3} xs={12}>
                            <div className={ classes.container_img }>
                                <img style={{ width: 250 }} src={ img } alt={ title }></img>
                            </div>
                        </Grid>

                        <Grid item xl={9} lg={9} xs={12}>
                            <div className={classes.container_text}>
                                <div className={ classes.textRecipeDisplay }>
                                    <span className={ classes.title }>{ title }</span>
                                    <br></br>
                                    <p className={ classes.text }>{ text }</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={1} lg={1} xs={1} />

            </Grid>
        </Grid>
    );
} 

export default Recipe;