import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css';
import { postContact ,contactValidations } from '../../../utils/BaseBuilder'

const useStyles = makeStyles(theme => ({

    container:{
        background: Colors.white,
        height: 450,
        width: 650,
        borderRadius: 20
    },

    containerForm:{
        display: 'flex', 
        justifyContent:'center', 
        width:'100%',
        height: '100%', 
        flexDirection:'column', 
        position: 'relative'
    },

    title:{
        fontFamily: 'Move', 
        width:'100%', 
        fontSize: 25 , 
        marginBottom: 15, 
    },

    input1: {
        fontFamily: 'Book',
        height: 30,
        border: `1px solid ${Colors.borderGray}`,
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 10,
        fontSize: 15,
        '&:focus':{
            outline: 'none'
        }
    },

    input2: {
        fontFamily: 'Book',
        border: `1px solid ${Colors.borderGray}`,
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 10,
        resize: 'none',
        height: 100,
        fontSize: 15,
        '&:focus':{
            outline: 'none'
        }
    },

    buttonSend: {
        height: 35,
        marginTop: 10,
        background: Colors.red,
        color: Colors.white,
        border: 0,
        fontFamily: 'Book',
        fontSize: 15,
        cursor: 'pointer', 
        '&:focus':{
            outline: 'none'
        }
        ,'&:active':{
            transition: '0.3s',
            background: Colors.white
        }
    } 

}))

const Form = ({ title, nameLabel, emaiLabel, messageLabel, send }) => {
    const classes = useStyles();
    const [ nameContact, setNameContact ]         = useState("");
    const [ phoneContact, setPhoneContact ]       = useState("");
    const [ emailContact, setEmailContact]        = useState("");
    const [ messageContact, setMessageContact ]   = useState("");

    const Send =  async() => {
        const res = contactValidations( nameContact, phoneContact, emailContact, messageContact );
        if(res){
            await postContact( nameContact, phoneContact, emailContact, messageContact )
            setNameContact(" ")
            setPhoneContact(" ")
            setEmailContact(" ")
            setMessageContact(" ")
        }
    }

    return(
        <div className={ classes.container }>
            <Grid container style={{ height: '100%' }}>
                <Grid item xl={1} lg={1} />
                
                <Grid item xl={10} lg={10} xs={12} style={{ display:'flex' }}>
                    <div className={ classes.containerForm }>
                        <span className={ classes.title }>{ title }</span>
                        <input className={ classes.input1 } value={ nameContact } type='text' placeholder={ nameLabel } maxLength='30' onChange={ e => setNameContact(e.target.value) } />
                        <input className={ classes.input1 } value={ phoneContact } type='text' placeholder='Teléfono' maxLength='30'    onChange={ e => setPhoneContact(e.target.value) } />
                        <input className={ classes.input1 } value={ emailContact } type='text' placeholder={ emaiLabel } maxLength='50' onChange={ e => setEmailContact(e.target.value) }/>
                        <textarea className={ classes.input2 } value={ messageContact } type='text' placeholder={messageLabel} maxLength='250' onChange={ e => setMessageContact(e.target.value) } />
                        <button className={ classes.buttonSend } onClick={ Send }>{ send }</button>
                    </div>
                </Grid>

                <Grid item xl={1} lg={1} />
            </Grid>
        </div>
    )
}

export default Form;