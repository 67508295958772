import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getProducts } from "../../../redux/actions/Cart";
import InfoContact from "../components/infoContact";
import PayPalButton from "../components/PayPalButton";
import { Table, TableRow, TableBody, TableCell, TableHead, Paper, IconButton, Button } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { formatMoney, postCodeDiscount } from "../../../utils/BaseBuilder";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../values";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "../../../media/icons/ic_delete.svg";
import { toast } from "react-toastify";

import "./../../../fonts/font.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 125,
    marginBottom: 125,
    overflowX: "auto",
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      marginTop: 50,
      marginBottom: 50,
    },
  },

  table: {
    minWidth: 700,
    fontFamily: "Book",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },

  inputDiscount: {
    textAlign: "center",
    paddingLeft: 15,
    height: 35,
    borderRadius: 5,
    textTransform: "uppercase",
    border: `1.5px solid ${Colors.grayForm}`,
    "&:focus": {
      outline: "none",
    },
  },

  tableHead: {
    background: Colors.black,
    "& th": {
      fontSize: 18,
      color: Colors.white,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  buttonContinue: {
    height: 40,
    paddingLeft: 40,
    paddingRight: 40,
    color: Colors.white,
    background: Colors.green,
    border: "none",
    fontFamily: "Book",
    fontWeight: "bold",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },

  displayTableDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  deleteProd: {
    background: "crimson",
    borderRadius: 5,
  },

  displayTableMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "block",
    },
  },

  displayButtonBack: {
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      marginLeft: 15,
    },
  },

  buttonArrow: {
    color: "white",
    fontSize: 16,
    width: 50,
    height: 32,
    border: "none",
    cursor: "pointer",
    borderRadius: 2,
    "&:focus": {
      outline: "none",
    },
  },
}));

const Cart = ({ openCart, setOpenCart, getProducts, Products }) => {
  const classes = useStyles();
  const [productDelete, setProductDelete] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [detailsCupon, setDetailsCupon] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [code, setCode] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [shipment, setShipment] = useState(165);
  // const shipment = 135
  const [formatDiscount, setFormatDiscount] = useState("");
  const [infoBuyer, setInfoBuyer] = useState({});

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    let SubTotal = 0;
    let Weight = 0;

    // Eliminate Product
    if (productDelete) {
      // console.log('Producto a Eliminar: ', productDelete );
      const ProductsFiltered = Products.filter((product) => product.Id !== productDelete);
      getProducts(ProductsFiltered);
      // console.log('Carrito sin el Producto Eliminado', Products );
      setProductDelete("");
    }

    if (Products.length === 0) {
      setSubTotal(0);
      setTotal(0);
    }

    // Calculate SubTotal
    Products.map((product) => {
      Weight += product.NetContent * product.Quantity;
      SubTotal += parseFloat(product.Subtotal);
      setSubTotal(SubTotal);
    });
    // console.log("PESO: ", Weight);
    // console.log("PESO CALC: ", (Weight/1000));

    const shipmentWeight = calculateShipment(Weight);
    setShipment(shipmentWeight);

    setTotal(subTotal);

    // Apply Discount Code
    if (detailsCupon) {
      if (detailsCupon.Expired === false) {
        let totalAux = 0;
        let discountAux = 0;
        setDiscountCode(code);
        setDiscount(detailsCupon.Discount);
        setDiscountType(detailsCupon.Type);
        if (discountType === "PERCENT") {
          discountAux = (discount * subTotal) / 100;
          // console.log('Descuento de Porcentaje: ', discountAux )
          totalAux = subTotal - discountAux;
          setFormatDiscount(` ${discount} % ($ ${formatMoney(discountAux)} MXN) `);
          setTotal(totalAux + shipment);
        } else {
          if (subTotal === 0) {
            setFormatDiscount(` $ ${formatMoney(discount)} MXN `);
            setTotal(subTotal + shipment);
          } else {
            totalAux = subTotal - discount;
            // console.log('descuento de Dinero: ', totalAux)
            setFormatDiscount(` $ ${formatMoney(discount)} MXN `);
            setTotal(totalAux + shipment);
            // toast.success( 'Descuento activado' )
          }
        }
      } else {
        setDiscountCode("NONE");
        setDiscountType("NONE");
        setFormatDiscount(` $ ${formatMoney(0)} MXN `);
        setTotal(subTotal + shipment);
      }
    } else {
      setDiscountCode("NONE");
      setDiscount("");
      setDiscountType("NONE");
      setDiscount(0);
      setTotal(subTotal + shipment);
    }
  }, [
    Products,
    Products.length,
    getProducts,
    productDelete,
    subTotal,
    total,
    detailsCupon,
    discount,
    shipment,
    discountType,
    discountCode,
    code,
  ]);

  const handleCodeChange = async (e) => {
    setCode(e.target.value.toUpperCase());

    // if (e.target.value.toUpperCase().length === 7) {
    //   const result = await postCodeDiscount(e.target.value.toUpperCase());
    //   // console.log("RES:", result);
    //   setDetailsCupon(result);
    // } else {
    //   setDiscountCode("NONE");
    //   setDiscount("");
    //   setDiscountType("NONE");
    //   setDiscount(0);
    //   setFormatDiscount(` $ ${formatMoney(0)} MXN `);
    //   setTotal(subTotal + shipment);
    // }
  };

  const calculateShipment = (Weight) => {
    let flag = true;
    let aux = 1;
    let inc = 5;

    while (flag === true) {
      if (Weight / 1000 > inc) {
        aux += 1;
        inc += 5;
      } else {
        flag = false;
      }
    }
    return aux * 165;
  };

  const handleDiscount = async () => {
    const result = await postCodeDiscount(code);
    // console.log("RES:", result);
    setDetailsCupon(result);
  };

  const handleIncrease = (pos) => {
    const aux = [...Products];
    aux[pos].Quantity += 1;
    getProducts(aux);
  };

  const handleSubstract = (pos) => {
    const aux = [...Products];
    aux[pos].Quantity = aux[pos].Quantity - 1 > 0 ? aux[pos].Quantity - 1 : aux[pos].Quantity;
    getProducts(aux);
  };

  const handleChangeValue = (value, pos) => {
    const aux = [...Products];
    aux[pos].Quantity = parseFloat(value);
    getProducts(aux);
  };

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      if (code) {
        handleDiscount();
      }
    }, 1000);
    return () => clearTimeout(delayTyping);
  });
  return (
    <div>
      <Fragment>
        <Paper className={classes.root}>
          <div className={classes.displayButtonBack}>
            <Button
              onClick={(e) => {
                setOpenCart(!openCart);
              }}
              className={classes.button}
              startIcon={<ArrowBackIcon />}
            >
              Volver a productos
            </Button>
          </div>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell style={{ width: "5%" }}> </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  CANTIDAD
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  PRODUCTO
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  PRECIO
                </TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  TOTAL
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.displayTableDesktop}>
              {Products.map((product, index) => (
                <TableRow>
                  <TableCell align="center" style={{ width: "5%" }}>
                    <IconButton onClick={() => setProductDelete(product.Id)}>
                      <img src={DeleteIcon} alt="Eliminar"></img>
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" style={{ width: "20%" }}>
                    <button
                      onClick={() => handleSubstract(index)}
                      type="button"
                      className={classes.buttonArrow}
                      style={{ background: Colors.black, marginRight: 2 }}
                      disabled={product.Quantity === 1}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={product.Quantity}
                      onChange={(e) => handleChangeValue(e.target.value, index)}
                      style={{ textAlign: "center", width: 50, height: 20, border: "none" }}
                    />
                    <button
                      onClick={() => handleIncrease(index)}
                      type="button"
                      className={classes.buttonArrow}
                      style={{ background: Colors.black, marginLeft: 2 }}
                    >
                      +
                    </button>
                  </TableCell>
                  <TableCell align="center" style={{ width: "20%" }}>
                    {product.Name}
                  </TableCell>
                  <TableCell align="center" style={{ width: "20%" }}>
                    $ {`${formatMoney(product.PublicPrice)}`} MXN
                  </TableCell>
                  <TableCell align="center" style={{ width: "20%" }}>
                    $ {`${formatMoney(product.Subtotal)}`} MXN
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell rowSpan={6} style={{ borderBottom: 0 }} />
                <TableCell rowSpan={6} style={{ borderBottom: 0 }} />
                <TableCell rowSpan={6} style={{ borderBottom: 0 }} />
                <TableCell style={{ borderBottom: 0 }}>
                  <b>Cupón</b>
                </TableCell>
                <TableCell align="center" style={{ padding: 0, borderBottom: 0 }}>
                  <input
                    className={classes.inputDiscount}
                    // maxLength="7"
                    onChange={handleCodeChange}
                  ></input>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 0 }}>
                  <b>Subtotal</b>
                </TableCell>
                <TableCell style={{ borderBottom: 0 }} align="center">{`$ ${formatMoney(subTotal)} MXN`}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 0 }}>
                  <b>Envío Todo México - 5 kg Máx.</b>
                </TableCell>
                <TableCell style={{ borderBottom: 0 }} align="center">{`$ ${formatMoney(shipment)} MXN`}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 0 }}>
                  <b>Descuento</b>
                </TableCell>
                <TableCell style={{ borderBottom: 0 }} align="center">
                  {formatDiscount}
                </TableCell>
              </TableRow>

              <TableRow style={{ background: Colors.greenTable }}>
                <TableCell style={{ borderBottom: 0 }}>
                  <b>TOTAL</b>
                </TableCell>
                <TableCell style={{ borderBottom: 0 }} align="center">
                  <b>{`$ ${formatMoney(total)} MXN `}</b>
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody className={classes.displayTableMobile}>
              {Products.map((product) => (
                <TableCell
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 15,
                    marginRight: 15,
                    marginBottom: 10,
                    borderRadius: 15,
                    background: "#F5F5F5",
                    border: 0,
                  }}
                >
                  <TableCell align="center">
                    <IconButton onClick={() => setProductDelete(product.Id)}>
                      <img src={DeleteIcon} alt="Eliminar"></img>
                    </IconButton>
                  </TableCell>

                  <TableCell style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                      <span style={{ fontWeight: "bold" }}> Producto: </span>
                    </div>

                    <div style={{ width: "60%" }}>
                      <span>{product.Name}</span>
                    </div>
                  </TableCell>

                  <TableCell style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                      <span style={{ fontWeight: "bold" }}> Cantidad: </span>
                    </div>

                    <div style={{ width: "60%" }}>
                      <span>{product.Quantity}</span>
                    </div>
                  </TableCell>

                  <TableCell style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                      <span style={{ fontWeight: "bold" }}> Precio: </span>
                    </div>

                    <div style={{ width: "60%" }}>
                      <span>{`$ ${formatMoney(product.PublicPrice)} MXN`}</span>
                    </div>
                  </TableCell>

                  <TableCell style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                      <span style={{ fontWeight: "bold" }}> Total: </span>
                    </div>
                    <div style={{ width: "60%" }}>
                      <span>{`$ ${product.Subtotal} MXN`}</span>
                    </div>
                  </TableCell>
                </TableCell>
              ))}

              <TableCell
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                  marginRight: 15,
                  marginBottom: 10,
                  borderRadius: 15,
                  background: "#F8F8F8",
                  border: 0,
                }}
              >
                {/* Discount Code */}
                <TableCell style={{ display: "flex" }}>
                  <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                    <b>Cúpon: </b>
                  </div>

                  <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                    <input className={classes.inputDiscount} maxLength="7" onChange={handleCodeChange}></input>
                  </div>
                </TableCell>

                {/* SubTotal */}
                <TableCell style={{ display: "flex" }}>
                  <div style={{ width: "40%" }}>
                    <b>Subtotal: </b>
                  </div>

                  <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                    <span>{`$ ${formatMoney(subTotal)} MXN`}</span>
                  </div>
                </TableCell>

                {/* Shipment */}
                <TableCell style={{ display: "flex" }}>
                  <div style={{ width: "40%" }}>
                    <b>Envío Todo México - 5 kg Máx. </b>
                  </div>

                  <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                    <span>{`$ ${formatMoney(shipment)} MXN`}</span>
                  </div>
                </TableCell>

                {/* Discount */}
                <TableCell style={{ display: "flex" }}>
                  <div style={{ width: "40%" }}>
                    <b>Descuento: </b>
                  </div>

                  <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                    <span>{formatDiscount}</span>
                  </div>
                </TableCell>

                {/* TOTAL */}
                <TableCell style={{ display: "flex", background: Colors.greenTable }}>
                  <div style={{ width: "40%" }}>
                    <b>TOTAL: </b>
                  </div>

                  <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                    <b>{`$ ${formatMoney(total)} MXN `}</b>
                  </div>
                </TableCell>
              </TableCell>
            </TableBody>
          </Table>
        </Paper>
      </Fragment>

      <div style={{ height: "100%" }}>
        <InfoContact setFlag={setFlag} flag={flag} setInfoContact={setInfoBuyer} arreglo={infoBuyer} />
      </div>

      {/* PayPal Button */}
      {/* style={{ display: flag? 'block' : 'none', height: '100%', marginTop: 100  }} */}
      <div style={{ display: flag ? "block" : "none", height: "100%", marginTop: 100 }}>
        <PayPalButton
          total={total}
          discountCode={discountCode}
          discount={discount}
          discountType={discountType}
          subTotal={subTotal}
          shipment={shipment}
          contact={infoBuyer}
        />
      </div>
    </div>
  );
};

const mapStateProps = (state) => ({
  Products: state.Cart.products,
});

export default connect(mapStateProps, { getProducts })(Cart);
