import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';
import '../../../fonts/font.css'

const useStyles = makeStyles(theme => ({
    content:{
        marginTop: 100,
        marginBottom: 50,
        padding: 0,
        [theme.breakpoints.down('sm')]:{
            marginTop: 20,
            marginBottom: 25,
        },
    },

    title:{
        fontSize: 50,
        fontFamily: 'Phosphate',
        color: Colors.red
    },

    subTitle: {
        fontFamily: 'Move',
        fontSize: 30,
        [theme.breakpoints.only('xs')]:{
            fontSize: 20,
        }
    },

    text: {
        whiteSpace: 'pre-line',
        fontFamily: 'Book',
        fontSize: 20,
        [theme.breakpoints.down('sm')]:{
            fontSize: 16,
            textAlign: "justify"
        }
    }

}))

const History = ({ title, subtitle, text }) => {
    const classes = useStyles()

    return(
        <div>
            <Container maxWidth={false} className={ classes.content }>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xl={1} lg={1} xs={1}/>
                    
                    <Grid item xl={10} lg={10} xs={10}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={ classes.title }>{ title }</span>
                            <span className={ classes.subTitle }>{ subtitle }</span>
                            <p className={ classes.text }>{ text }</p>
                        </div>
                    </Grid>
    
                    <Grid item xl={1} lg={1} xs={1} />
                </Grid>
            </Container>
        </div>
    );
}

export default History; 