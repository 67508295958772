import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../values';
import { connect } from 'react-redux';
import { clearProducts, getProducts } from '../../../redux/actions/Cart';
import PayPalExpressButton from 'react-paypal-express-checkout';
import { toast } from 'react-toastify';
import { postSale } from '../../../utils/BaseBuilder';

const useStyles = makeStyles(theme =>({
    button: {
        '&:focus':{
            outline: 'none'
        },
    },

    buttonAnimation: {
        animation: `$pulsate-fwd 1.5s ease-in-out infinite both`,
        },
        '@keyframes pulsate-fwd': {
            '0%': {
                transform: 'scale(1)',
            },
            '50%': {
                transform: 'scale(1.1)',
            },
            '100%': {
                transform: 'scale(1)',
            }
        }
    
}))
const ButtonPayPal = ({ discountCode, discountType, discount, subTotal, shipment, contact, clearProducts, getProducts, Products, total }) => {
    const classes = useStyles()
    // const env = 'sandbox';
    const env = 'production';
    const currency = 'MXN';
    const client = {
        sandbox: 'AU9am37lXuiRApBrK9G9sB_1iSJzYHjfxj49RehDn-EkF4btWf7yoh396utn3N4RCMN-CZNJ-rdYx1vN',
        production: 'AYmG_aOpaIuXGRsog9yitmIqK9UZTyG55bELFid0ZI8fe5F-eFpJ3EMGzafaOnnVYHfDMdQTOOs90HWX'
    }

    const onSuccess = () => {
        toast.success('Pago exitoso, se te enviara un correo.')
        postSale( Products, discountCode, discountType, discount, subTotal, shipment, contact )
        clearProducts();
    }
    const onCancel = () => {
        toast.error('El pago fue cancelado');
    }
    const onError = () => {
        toast.warn('Ocurrió un error al procesar tu pago. Por favor intentalo de nuevo más tarde');
    }

    return(
        <Grid container>
            <Grid item xl={2} md={2} xs={1} />

            <Grid item xl={8} md={8} xs={10}>
                <Grid container>
                    <Grid item xl={12} md={12} xs={12} style={{ marginBottom: 20 }}>
                        <span style={{ fontSize: 22, fontFamily: 'Heavy', fontWeight: 'bold' }}> PAGO </span>
                    </Grid>
                    <Grid item xl={12} md={12} xs={12} style={{ background: Colors.white, border: `1px solid ${Colors.grayForm}`, height: 200, borderRadius: 5 }}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems:'center', justifyContent:'center'}}>
                            {/* <button onClick={ () => Prueba() }>PRUEBA</button> */}
                            <div className={classes.buttonAnimation} style= {{ borderRadius: 10, padding: 15, boxShadow: '0px 2px 12px -5px rgba(0,0,0,0.35)'}}>
                                <PayPalExpressButton
                                    className = { classes.button }
                                    env = { env }
                                    client = { client }
                                    currency = {currency}
                                    total   = { Math.round(total) }
                                    onError={onError}
                                    onSuccess={onSuccess}
                                    onCancel={onCancel} 
                                    style = {{ 
                                        size: 'medium',
                                        height: 45,
                                        shape: 'pill',
                                        label: 'paypal',
                                        tagline: 'false',
                                        color: 'silver',
                                        layout: 'horizontal',
                                        fundingicons: true,
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        
            <Grid item xl={2} md={2} xs={1} />
        </Grid>
    )
}

const mapStateProps = state => ({
    Products:       state.Cart.products,
})

export default connect(mapStateProps,{clearProducts, getProducts} )(ButtonPayPal)