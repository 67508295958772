import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/Styles';
import { Colors } from '../../../values';
import { infoContactValidations } from '../../../utils/BaseBuilder'
import { toast } from 'react-toastify';
import '../../../fonts/font.css';
const useStyles = makeStyles(theme => ({
    buttonContinue: {
        height: 40,
        paddingLeft: 40,
        paddingRight: 40,
        color: Colors.white,
        border: 'none',
        fontFamily: 'Book',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:focus':{
            outline: 'none'
        }
    },

    selectForm: {
        border: `1.5px solid ${Colors.grayForm}`, 
        height: 40,
        width: 422,
        borderRadius: 6, 
        marginBottom: 10,
        '&:focus':{
            outline: 'none'
        }
    },

    displayInfoContactTitle:{
        marginBottom: 20,
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent: 'center'
        }
    },

    infoContactTitle:{
        fontSize: 22, 
        fontFamily: 'Heavy', 
        fontWeight: 'bold',
    },

    displayInputs:{
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    },

    displayInputs2:{
        display:'flex', 
        flexDirection:'column', 
        alignItems:'flex-end',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'center',
            alignItems: 'center',
        }
    },

    inputs:{
        height: 35,
        borderRadius: 5,
        border: `1.5px solid ${Colors.grayForm}`,
        marginBottom: 10,
        paddingLeft: 20,
        fontFamily: 'Book',
        width: '97%',
        fontSize: 15,
        '&:focus':{
            outline: 'none'
        },
        [theme.breakpoints.down('sm')]:{
            width: '90%'
        },
        '&:disabled':{
            background: Colors.white
        }
    },

    input: {
        height: 35,
        borderRadius: 5,
        border: `1.5px solid ${Colors.grayForm}`,
        marginBottom: 10,
        paddingLeft: 20,
        fontFamily: 'Book',
        width: '93%',
        fontSize: 15,
        '&:focus':{
            outline: 'none'
        },
        [theme.breakpoints.down('sm')]:{
            width: '90%'
        },
        '&:disabled':{
            background: Colors.white
        }
    }
}))

const InfoContact = ({ flag, setFlag, arreglo, setInfoContact }) => {
    const classes = useStyles();

    const [ name, setName]                  = useState('');
    const [ address , setAddress]           = useState('');
    const [ state, setState ]               = useState('');
    const [ colony, setColony ]             = useState('');
    const [ phone, setPhone ]               = useState('');
    const [ municipality, setMunicipality ] = useState('');
    const [ postalCode, setPostalCode ]     = useState('');
    const [ email, setEmail ]               = useState(''); 

    // const [ arreglo, setArreglo ]           = useState([])

    const values = async() =>{
        const res = infoContactValidations( name, address, state, colony, phone, municipality, postalCode, email )
        if(res){
            setFlag(!flag)
            if(flag === false){
                arreglo.Name         = name
                arreglo.Addres       = address
                arreglo.State        = state
                arreglo.Colony       = colony
                arreglo.Phone        = phone
                arreglo.PostalCode   = postalCode
                arreglo.Municipality = municipality
                arreglo.Email        = email
                toast.success('Los datos introducidos fueron validados')
                console.log('Contact: ', arreglo )
            }
        }
    }

    return(
        <Grid container> 
            <Grid item lg={2} md={1} xs={1}/> 
            
            <Grid item lg={8} md={10} xs={10} >
                <Grid container>
                    <Grid item xl={12} md={12} xs={12} className={ classes.displayInfoContactTitle }>
                        <span className={ classes.infoContactTitle }> INFORMACION DE CONTACTO </span>
                    </Grid>

                    <Grid item xl={12} md={12} xs={12} className={ classes.displayInputs }>
                        <input className={ classes.inputs } maxLength='30' placeholder='Nombre' onChange={ e => setName(e.target.value)} disabled={flag} ></input>
                        <input className={ classes.inputs } maxLength='50' placeholder='Calle y Numero' onChange={ e => setAddress(e.target.value) } disabled={flag}></input>
                    </Grid> 

                    <Grid item xl={6} md={6} xs={12} className={ classes.displayInputs } >

                            <input className={ classes.input } placeholder='Estado' list='states' onChange={ e => setState(e.target.value) } disabled={flag} ></input>
                            <datalist id='states'>
                                <option value='Aguascalientes'>Aguascalientes</option>
                                <option value='Baja California'>Baja California</option>
                                <option value='Baja California Sur'>Baja California Sur</option>
                                <option value='Campeche'>Campeche</option>
                                <option value='Chiapas'>Chiapas</option>
                                <option value='Chihuahua'>Chihuahua</option>
                                <option value='Coahuila'>Coahuila</option>
                                <option value='Colima'>Colima</option>
                                <option value='Distrito Federal'>Distrito Federal</option>
                                <option value='Durango'>Durango</option>
                                <option value='Estado de México'>Estado de México</option>
                                <option value='Guanajuato'>Guanajuato</option>
                                <option value='Guerrero'>Guerrero</option>
                                <option value='Hidalgo'>Hidalgo</option>
                                <option value='Jalisco'>Jalisco</option>
                                <option value='Michoacán'>Michoacán</option>
                                <option value='Morelos'>Morelos</option>
                                <option value='Nayarit'>Nayarit</option>
                                <option value='Nuevo León'>Nuevo León</option>
                                <option value='Oaxaca'>Oaxaca</option>
                                <option value='Puebla'>Puebla</option>
                                <option value='Querétaro'>Querétaro</option>
                                <option value='Quintana Roo'>Quintana Roo</option>
                                <option value='San Luis Potosí'>San Luis Potosí</option>
                                <option value='Sinaloa'>Sinaloa</option>
                                <option value='Sonora'>Sonora</option>
                                <option value='Tabasco'>Tabasco</option>
                                <option value='Tamaulipas'>Tamaulipas</option>
                                <option value='Tlaxcala'>Tlaxcala</option>
                                <option value='Veracruz'>Veracruz</option>
                                <option value='Yucatán'>Yucatán</option>
                                <option value='Zacatecas'>Zacatecas</option>
                            </datalist>
                        <input className={ classes.input } maxLength='30' placeholder='Colonia' onChange={ e => setColony(e.target.value) } disabled={flag} ></input>
                        <input className={ classes.input } maxLength='30' placeholder='Teléfono' onChange={ e => setPhone(e.target.value) } disabled={flag} ></input> 
                    </Grid> 

                    <Grid item xl={6} md={6} xs={12} className={ classes.displayInputs2 }>
                        <input className={ classes.input } maxLength='30' placeholder='Municipio' onChange={ e => setMunicipality(e.target.value) } disabled={flag} ></input>
                        <input className={ classes.input } maxLength='10' placeholder='Código Postal' onChange={ e => setPostalCode(e.target.value) } disabled={flag} ></input>
                        <input className={ classes.input } maxLength='50' placeholder='Correo' onChange={ e => setEmail(e.target.value) } disabled={flag}></input>
                    </Grid>

                    <Grid item xl={6} md={6}  />

                    <Grid item xl={6} md={6} xs={12} style={{ display:'flex', justifyContent: 'center', alignItems:'center' }}>
                        <button className={ classes.buttonContinue } style={{ background: flag? Colors.orange : Colors.green }} onClick={() => values() } >{ flag? 'MODIFICAR': 'VALIDAR'}</button>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item lg={2} md={1} xs={1} /> 
        </Grid>
    );
}
export default InfoContact