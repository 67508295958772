import React from 'react'

import Main from '../global/Main';
import History from './components/History';
import MissionVision from './components/MissionVision';
import Touch from './components/Touch';
import Clients from './components/Clients';
import { Colors } from '../../values';

const Company = ({ baseContent }) => {
    // console.log('Contenido en Company:',  baseContent )
    return(
        <div>
            { baseContent.map ((item,index) =>
                <div key={index}>
                    <div style={{ position: 'relative' }}>
                        <Main
                            key = {' '}
                            bgColor1 = {'#42B748'}
                            bgColor2 = {'#349239'}
                            title   = { item.Company.Main }
                            icon    = { item.Company.Icon }
                            widthImg = '125px'
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <History 
                            title       = { item.Company.Title }
                            subtitle    = { item.Company.Subtitle }
                            text        = { item.Company.Description }
                        />
                    </div> 

                    <div style={{ position: 'relative' }}>
                        <MissionVision 
                            titleVision       = { item.Company.Vision.Title }
                            textVision        = { item.Company.Vision.Description}
                            titleMission      = { item.Company.Mission.Title }
                            textMission       = { item.Company.Mission.Description}
                        />
                    </div>

                    <div style={{ position: 'relative' }}>
                        <Touch  
                            title       = {item.Company.Clients.Title}
                            subTitle    = {item.Company.Clients.Subtitle}
                        />
                    </div>

                    <div style={{ position: 'relative'}}>
                        <Clients
                            img1 = { item.Company.Clients.Images[index] }
                            img2 = { item.Company.Clients.Images[index+1] }
                            img3 = { item.Company.Clients.Images[index+2] }
                            img4 = { item.Company.Clients.Images[index+3] }
                            img5 = { item.Company.Clients.Images[index+4] }
                        /> 
                    </div>
                
                </div>
            )}
        </div>
    );

}

export default Company;
