import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../../values';
import Product from './Product';

const useStyles = makeStyles(theme => ({

    content:{
        marginTop: 20,
        marginBottom: 20,
        padding: 0,
        
    },

    containerProduct:{
        height:'100%', 
        width:'100%', 
        // background:Colors.productsBG,
    },

    contentMiddle:{
        [theme.breakpoints.down('md')]:{
            marginTop: 10,
            marginBottom: 10
        }
    },

    containerMiddle:{
        height:'100%', 
        width:'95%', 
        // background:Colors.productsBG,
        [theme.breakpoints.down('md')]:{
            width: '100%',
        }
    }
}))

const Products = ({ imgProduct1, text1, imgProduct2, text2, category2, imgProduct3, text3, background1, background2, background3 }) => {
    const classes = useStyles();
    return(
        <Container maxWidth={false} className={classes.content}>
            <Grid container style={{ height: '100%' }}>

                <Grid item xl={4} lg={4} xs={12}>
                    <div style={{ height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'  }}>
                        <div className={ classes.containerProduct } style={{background:background1}}>
                            <Product 
                                image   = { imgProduct1 }
                                title   = { text1 }
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xl={4} lg={4} xs={12} className={ classes.contentMiddle }>
                    <div style={{ height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                        <div className={ classes.containerMiddle } style={{background:background2}}>
                            <Product 
                                image   = { imgProduct2 }
                                title   = { text2 }
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xl={4} lg={4} xs={12}>
                    <div style={{ height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                        <div className={ classes.containerProduct } style={{background:background3}}>
                            <Product 
                                image   = { imgProduct3 }
                                title   = { text3 }
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Products;