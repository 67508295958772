import React, { useState } from 'react'
import { Container, Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { menuItem } from '../../values';
import { Colors } from '../../values';
import { makeStyles } from '@material-ui/core/styles';
import NavBarMobile from './NavBarMobile';
import '../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    buttonBar: {
        selected: '0.3s', 
        background: Colors.white,
        fontSize: 16,
        color: Colors.grayDark,
        fontFamily: 'Move',
        fontWeight: 'bold',
        paddingRight:15,  
        paddingLeft:15, 
        paddingBottom:3, 
        borderRadius:0,
        '&:hover':{
            background: Colors.white,
            color: Colors.gray,
        },
    },

    buttonBarSelected: {
        selected: '0.3',
        background: Colors.white,
        fontSize: 16,
        color: Colors.red,
        fontFamily:'Move',
        fontWeight: 'bold',
        paddingRight:15, 
        paddingLeft:15, 
        paddingBottom:1.5, 
        borderRadius:0,
        '&:hover':{
            background: Colors.white,
            color: Colors.gray,
        },
    },

    menuDesktop:{
        [theme.breakpoints.down('md')]: {
            display:'none'
        },
    },

    menuMobile:{
        display: 'none',
        [theme.breakpoints.down('md')]:{
            display: 'block'
        }
    },

    content1: {
        background:Colors.white, 
        paddingTop:25,
        paddingBottom:25,
        display:'flex', 
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            paddingBottom: 0

        }
    },

    content2: {
        textAlign: 'center'
    },

    logo:{
        width: 200,
        [theme.breakpoints.down('md')]:{
            width: 125,
        }
    }
}))

const NavBar = ({ baseContent }) =>{
    const classes = useStyles();

    const [path, setPath] = useState('home');
    
    return(
        <div className={ classes.content1 }>
            <Container maxWidth={false} style={{ padding:0 }}>
                <Grid container>

                    <Grid item xs={1} />

                    <Grid item xs={4}>
                        <div>
                            <Button to={`/`} component={ RouterLink }>
                                { baseContent.map((item, index) => 
                                    <span key={index} style={{textAlign:'left', maxWidth:200}}>
                                        <img alt='logo' className={ classes.logo } src={item.TopBar.Logo} />
                                    </span>
                                )}
                            </Button> 
                        </div>
                    </Grid>


                    <Grid item xs={6} className={classes.content2}>
                        <div style={{ width:'100%', height:'100%',display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                            <div className={classes.menuDesktop}>
                                {
                                    menuItem.map((item,index)=>
                                        (item.title.toLocaleLowerCase() === (window.location.pathname).replace('/',''))?
                                                <Button className={classes.buttonBarSelected} style={{textTransform:'none'}} 
                                                    onClick={ () => setPath(item.title) }
                                                    key={item.id} 
                                                    to={`/${path.toLowerCase()}`} 
                                                    component={RouterLink} > {item.title} 
                                                </Button>
                                            :
                                                window.location.pathname === '/' &&  item.title.toLowerCase() === "inicio" ?
                                                    <Button className={classes.buttonBarSelected} style={{textTransform:'none'}} 
                                                        onClick={ () => setPath(item.title) }
                                                        key={item.id} 
                                                        to={`/${item.title.toLowerCase()}`} 
                                                        component={RouterLink} > {item.title} 
                                                    </Button>
                                            :
                                                <Button 
                                                    className={classes.buttonBar} 
                                                    onClick={ () => setPath(item.title) }
                                                    style={{textTransform:'none'}}  key={item.id} to={`/${item.title.toLowerCase()}`} component={RouterLink} > {item.title} 
                                                </Button>
                                    )
                                }

                            </div>
                            <div className={ classes.menuMobile }>
                                <NavBarMobile />
                            </div>
                        </div>
                    </Grid>
                    
                    <Grid item xs={1} />

                </Grid>
            </Container>
        </div>
    );
}

export default NavBar;