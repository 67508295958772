////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////                          MENU                      //////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const menuItem = [
    {id: 1, title:'INICIO'},
    {id: 2, title:'EMPRESA'},
    {id: 3, title:'PRODUCTOS'},
    {id: 4, title:'RECETAS'},
    {id: 5, title:'CONTACTO'} ];

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////                          COLORS                    //////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const Colors = {
    white:          '#FFFFFF',
    black:          '#000000',
    red:            '#A02720',
    redAdd:         '#D72E26',
    green:          '#06A357',
    greenTable:     '#BDE6D2',
    productsBG:     '#FFCF70',
    orange:         '#E99A01',
    orangeSub:      '#F7C844',
    orangeAdd:      '#F19938',
    bannerBG:       '#FDF2DC',
    gray:           '#4C535E',
    borderGray:     '#D2D2D2',
    grayDark:       '#2C3138',
    grayForm:       '#EEEEEE',
    darkGrayishBlue:'#172326',
}
