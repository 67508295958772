import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import { Colors } from "../../../values";
import "../../../fonts/font.css";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 25,
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 25,
      marginBottom: 25,
    },
  },

  background: {
    // width:'100vw',
    height: 750,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "auto",
    },
  },

  banner_img_container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  imgMain: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  banner_content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  title: {
    color: Colors.white,
    fontSize: 150,
    textAlign: "center",
    fontFamily: "Gochi",
    [theme.breakpoints.down("md")]: {
      fontSize: 75,
    },
  },

  subTitle: {
    color: Colors.white,
    fontSize: 40,
    textAlign: "center",
    fontWeight: 500,
    fontFamily: "Move",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      marginBottom: 10,
    },
  },

  icons: {
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 50,
      marginTop: 10,
    },
  },

  buttonShadow: {
    transition: "0.3s",
    transform: "scale(1.0)",
    "&:hover": {
      transition: "0.3s",
      transform: "scale(1.10)",
    },
    [theme.breakpoints.down("md")]: {
      width: 65,
    },
  },

  buttonGame: {
    transition: "0.3s",
    transform: "scale(1.0)",
    "&:hover": {
      transition: "0.3s",
      transform: "scale(1.10)",
    },
    [theme.breakpoints.down("md")]: {
      width: 125,
    },
  },
}));

const Main = ({ background, banner_img, title, subTitle, iconFB, linkFb, iconIG, linkIg, iconGame, linkGame }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.background} style={{ background: "#a4d700", backgroundImage: `url(${background})` }}>
            <Grid container>
              <Grid item xl={5} lg={5} xs={12}>
                <div className={classes.banner_img_container}>
                  <img className={classes.imgMain} src={banner_img} alt="Micho" />
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={12} xs={12}>
                <div className={classes.banner_content}>
                  <span className={classes.title}>{title}</span>
                  <span className={classes.subTitle}>{subTitle}</span>

                  <div className={classes.icons} style={{ width: "100%", display: "flex" }}>
                    {/* Icon FB  */}
                    <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
                      <div style={{ display: "flex" }}>
                        <IconButton href={linkFb} target="_blank">
                          <img
                            className={classes.buttonShadow}
                            style={{ borderRadius: "50%", cursor: "pointer" }}
                            src={iconFB}
                            alt="Icon FB"
                          ></img>
                        </IconButton>
                      </div>
                    </div>

                    {/* IconGame */}
                    <div style={{ width: "40%", display: "flex", justifyContent: "center" }}>
                      <div style={{ display: "flex" }}>
                        <IconButton href="https://micho-wings.web.app" target="_blank">
                          <img
                            className={classes.buttonGame}
                            style={{ cursor: "pointer" }}
                            src={iconGame}
                            alt=" Icono Juego "
                          ></img>
                        </IconButton>
                      </div>
                    </div>

                    {/* Icon IG */}
                    <div style={{ width: "30%", display: "flex" }}>
                      <div style={{ display: "flex" }}>
                        <IconButton href={linkIg} target="_blank">
                          <img
                            className={classes.buttonShadow}
                            style={{ borderRadius: "50%", cursor: "pointer" }}
                            src={iconIG}
                            alt=" Icono IG "
                          ></img>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xl={1} lg={1} />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
