import React from 'react';
import Client from './Client'
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content:{
        marginTop: 20,
        marginBottom: 200,
        padding: 0,
        [theme.breakpoints.down('md')]:{
            marginBottom: 75,
        }
    },

    displayClient:{
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column',
        }
    },

    dimensionImages:{
        width: '20%',
        [theme.breakpoints.down('md')]:{
            width: '100%'
        }
    }

}))

const Clients = ({ img1, img2, img3, img4, img5 }) => {
    const classes = useStyles()
    return(
        <div>
            <Container maxWidth={false} className={ classes.content }>
                <div className={ classes.displayClient }>
                    <div className={ classes.dimensionImages }>
                        <Client
                            img = { img1 }
                        />
                    </div>

                    <div className={ classes.dimensionImages }>
                        <Client
                            img = { img2 }
                        />
                    </div>

                    <div className={ classes.dimensionImages }>
                        <Client
                            img = { img3 }
                        />
                    </div>

                    <div className={ classes.dimensionImages }>
                        <Client
                            img = { img4 }
                        />
                    </div>

                    <div className={ classes.dimensionImages }>
                        <Client
                            img = { img5 }
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Clients;