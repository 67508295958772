import React from 'react'
import Touch from './components/Touch';
import Contact from './components/Contact'; 
import  Map from './components/Map'

const Company = ({ baseContent }) => {

    return(
        <div>
            { baseContent.map ((item,index) => 
            <div key={index}>
                <div style={{ position: 'relative' }}>
                    <Touch  
                        key         = {' '}
                        title       = {item.Contact.Title}
                        subTitle    = {item.Contact.Subtitle}
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <Contact 
                        title               = { item.Contact.Form.Title }
                        nameLabel           = { item.Contact.Form.Name }
                        emaiLabel           = { item.Contact.Form.Email }
                        messageLabel        = { item.Contact.Form.Message }
                        send                = { item.Contact.Form.Send }

                        locationIcon        = { item.Contact.Data.Location.Icon }       
                        locationTitle       = { item.Contact.Data.Location.Title }
                        locationDesc        = { item.Contact.Data.Location.Description }  

                        phoneIcon           = { item.Contact.Data.Phone.Icon }
                        phoneTitle          = { item.Contact.Data.Phone.Title }                                                                                               
                        phoneDesc           = { item.Contact.Data.Phone.Description }

                        emailIcon           = { item.Contact.Data.Email.Icon }
                        emailTitle          = { item.Contact.Data.Email.Title }
                        emailDesc           = { item.Contact.Data.Email.Description }
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <Map
                        markerIcon             = { item.Company.Icon }
                    /> 
                </div> 
            </div>
            )}
        </div>
    );
}

export default Company;