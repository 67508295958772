import React from 'react'
import Main from '../global/Main';
import Touch from './components/Touch';
import RecipeSingle from './components/Recipe';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../values';

const useStyles = makeStyles(theme =>({

    content: {
        marginBottom: 100,
        [theme.breakpoints.down('md')]:{
            marginBottom: 50
        }
    },
}))

const Company = ({ baseContent, dataRecipes }) => {
    const classes = useStyles()

    return(
        <div>
            { baseContent.map ((item,index) => 
            <div key={index}>
                <div style={{ position: 'relative' }}>
                    <Main
                        widthImg    = '160px'
                        bgColor1 = {'#A754A5'}
                        bgColor2 = {'#742071'}
                        title       = { item.Recipes.Main }
                        icon        = { item.Recipes.Icon }
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <Touch  
                        title       = { item.Recipes.Title }
                        subTitle    = { item.Recipes.Subtitle }
                    />
                </div>

                <Grid container className={ classes.content }>
                    { dataRecipes.map((item, index) => 
                        <RecipeSingle
                            key={index}
                            img     = { item.Image }
                            title   = { item.Name }
                            text    = { item.Description }
                        />
                    )}
                </Grid>

            </div>
            )}
        </div>
    );
}

export default Company;
