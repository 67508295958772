export const getProducts = (data) =>{
    return (dispatch) => {
        dispatch({
            type:       'AGREGA',
            data:       data,
        })
    }
}

export const clearProducts = () =>{
    return (dispatch) => {
        dispatch({
            type:       'LIMPIAR',
        })
    }
}

export const categorySelected = (data) => {
    return (dispatch) => {
        dispatch({
            type:   'CAMBIAR',
            data:   data
        })
    }
}

export const  sendSale = (data) => {
    return (dispatch) => {
        dispatch({
            type:   'ENVIAR',
            data:   data
        })
    }
}