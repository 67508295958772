import React from 'react';
import { Grid, Typography, InputBase } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../values';

import './../../../fonts/font.css';

const useStyles = makeStyles(theme => ({

    textBold: {
        fontFamily: 'Heavy',
        fontSize: 25,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            textAlign: 'center'
        }
    },

    textLight: {
        fontFamily: 'Book',
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
            textAlign: 'center'
        }
    },

    input: {
        color: Colors.black,
        width: 75,
        padding: '7px 0px',
        fontFamily: 'Book',
        background: Colors.borderGray,
        borderRadius: 2,
        textAlign: "center",
        align: "center",
        border: 'none',
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }

    },

    button: {
        width: 50,
        height: 32,
        border: 'none',
        cursor: 'pointer',
        borderRadius: 2,
        '&:focus': {
            outline: 'none'
        }
    },

    number: {
        width: 125,
        height: 30,
        marginLeft: 2,
        marginRight: 2,
        background: Colors.grayForm,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    buttonAdd: {
        height: 32,
        paddingLeft: 25,
        paddingRight: 25,
        color: Colors.white,
        border: 'none',
        borderRadius: 2,
        cursor: 'pointer',
        background: Colors.redAdd,
        '&:focus': {
            outline: 'none'
        }
    },

    dialogContent: {
        width: 900,
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },

    dialogContentGrid: {
        flexGrow: 1,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },

    imgProductContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: 'red'
    },

    imgProduct: {
        width: 300,
        [theme.breakpoints.down('sm')]: {
            width: 275
        }
    },

    infoProductContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        // background: 'skyblue'
    },

    displayAddProducts: {
        display: 'flex',
        alignItems: 'center',
        // background: 'green',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },

    displayAddButton: {
        display: 'flex',
        alignItems: 'center',
        // background: 'green',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    }
}))

const Modal = ({ nameProduct, descriptionProduct, imageProduct, handleClose, open, quantity, handleLessQuantity, handleAddQuantity, handleValueQuantity, subTotal, handleAddCart }) => {
    const classes = useStyles()
    console.log('quantity: ', quantity);
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>

            <DialogContent className={classes.dialogContent}>
                <Grid content className={classes.dialogContentGrid}>

                    <Grid item xl={5} md={12} xs={12} className={classes.imgProductContainer} >
                        <img className={classes.imgProduct} src={imageProduct} alt={nameProduct}></img>
                    </Grid>

                    <Grid item xl={7} md={12} xs={12} className={classes.infoProductContainer}>
                        <Typography variant="body1" component="p" className={classes.textBold}> {nameProduct} </Typography>
                        <Typography variant="body1" component="p" className={classes.textLight}> {descriptionProduct} </Typography>
                    </Grid>

                </Grid>
            </DialogContent>

            <form onSubmit={handleAddCart}>
                <DialogActions style={{ padding: 20, paddingTop: 0 }} >
                    <Grid container justify="left" spacing={2} direction="row" alignItems="right">
                        <Grid item xl={5} lg={5} md={5} />
                        <Grid item xl={4} lg={4} md={12} xs={12} className={classes.displayAddProducts}>
                            <button type='button' className={classes.button} style={{ background: Colors.orangeAdd, marginRight: 2 }} disabled={quantity === 1} onClick={() => { handleLessQuantity() }} >
                                <NavigateBeforeIcon style={{ width: 30 }} />
                            </button>
                            <input type='number' className={classes.input} value={quantity} onChange={(e) => handleValueQuantity(e.target.value)} required />
                            <button type='button' className={classes.button} style={{ background: Colors.orangeAdd, marginLeft: 2 }} onClick={() => { handleAddQuantity() }} >
                                <NavigateNextIcon style={{ width: 30 }} />
                            </button>
                        </Grid>

                        {/* Button Add */}
                        <Grid item xl={3} lg={3} md={12} xs={12} className={classes.displayAddButton}>
                            <button type='submit' className={classes.buttonAdd} disabled={quantity === '' || quantity === '0'}>
                                AGREGAR AL CARRITO
                            </button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog >
    );

}


export default Modal;